// src/components/LoadingSpinner.js
import React from 'react';

const LoadingSpinner = () => (
  <div className="spinner-container">
    <div className="loading-spinner">
      Loading...
    </div>
  </div>
);

export default LoadingSpinner;
