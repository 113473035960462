import React, { useEffect, useState } from 'react'
import axiosInstance from '../utils/axiosInstance'
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function Attributes() {
   const [attributes, setAttributes] = useState([]);
   const [categories, setCategories] = useState([]);
   const [subCategories, setSubCategories] = useState([]);
   const [isCategorySelected, setIsCategorySelected] = useState(false);
   const [selectedCategory, setSelectedCategory] = useState({ id: '-1', name: '', sub_categories: [] })
   const [selectedSubCategory, setSelectedSubCategory] = useState('-1')
   const [loadingValues, setLoadingValues] = useState(true);
   const [currentAttribute, setCurrentAttribute] = useState({ id: '', name: '', values: [] })
   const [deletedAttribute, setDeletedAttribute] = useState([])
   const [showModal, setShowModal] = useState(false);
   const [modalMode, setModalMode] = useState(''); // 'create' or 'update' or 'delete'
   const [attributeValuesItems, setAttributeValuesItems] = useState([
      {
         key: generateUniqueKey(),
         value: ''
      }
   ]);

   function generateUniqueKey() {
      return Date.now() + Math.random().toString(36).substr(2, 9);
   }
   // Toggle modal visibility and reset states
   const toggleModal = (currentAttribute = null, mode = '') => {
      setCurrentAttribute({ ...currentAttribute });
      setAttributeValuesItems([{ key: generateUniqueKey(), value: '' }]);
      setModalMode(mode);
      setSelectedSubCategory('-1');
      setIsCategorySelected(false);
      setSelectedCategory({ id: '-1', name: '', sub_categories: [] });
      setDeletedAttribute([]);
      setLoadingValues(true);
      setShowModal(!showModal);
   };

   // Handle checkbox changes for linking attribute values to subcategories
   const handleCheckboxChange = (valueId) => {
      setCurrentAttribute(prev => ({
         ...prev,
         values: prev.values.map(value =>
            value.id === valueId ? { ...value, linked: !value.linked } : value
         )
      }));
   };

   // Handle category selection
   const handleCategoryChange = (value) => {
      let found = false;
      categories.forEach((category) => {
         if (value == category.id) {
            found = true;
            setSelectedCategory(category);
            setSubCategories(category.sub_categories);
         }
         setIsCategorySelected(true);
      });
      if (!found) {
         setSubCategories([{ id: '-2', name: 'No subcategory found' }]);
      }
   }

   // Handle subcategory selection
   const handleSubCategoryChange = (value) => {
      setSelectedSubCategory(value)
      fetchSubCategoriesValues(currentAttribute.id, value)
      console.log(currentAttribute.id + '  value : ' + value)
   }

   // Add new value to the current attribute
   const handleAddCurrentAttributeValue = () => {
      setCurrentAttribute(prevAttribute => {
         const newAttribute = { ...prevAttribute };
         newAttribute.values = [...(newAttribute.values || []), { value: '' }];
         return newAttribute;
      })
   }

   // Delete a value from the current attribute
   const handleDeleteCurrentAttributeValueItem = (index) => {
      setCurrentAttribute(prevAttribute => {
         const newAttribute = { ...prevAttribute };

         newAttribute.values = newAttribute.values.map((value, i) => {
            if (i == index) {
               if (value.value != '') {
                  setDeletedAttribute([...deletedAttribute, value])
                  console.log(value)
               }
            }
            return value
         });
         newAttribute.values = newAttribute.values.filter((_, i) => i !== index);
         return newAttribute;
      });
   };

   // Handle change in the attribute value input
   const handleChangeCurrentAttributeValue = (index, event) => {
      const { value } = event.target;
      setCurrentAttribute(prevAttribute => {
         const newAttribute = { ...prevAttribute };
         newAttribute.values[index].value = value
         return newAttribute
      })
   }

   // Add a new attribute value input field
   const handleAddAttributeValueItem = () => {
      setAttributeValuesItems([...attributeValuesItems, {
         key: generateUniqueKey(),
         value: ''
      }])
   }

   // Handle change in the new attribute value input
   const handleAttributeValueChange = (index, event) => {
      const { value } = event.target;
      setAttributeValuesItems(prevItems => {
         const newItems = [...prevItems];
         newItems[index].value = value
         return newItems;
      });
   };

   // Delete an attribute value input field
   const handleDeleteAttributeValueItem = (index) => {
      setAttributeValuesItems(prevItems => prevItems.filter((_, i) => i !== index));
   };

   // Handle the linking of attribute values to subcategories
   const handleLink = async () => {
      try {
         const data = { attributeId: currentAttribute.id, values: currentAttribute.values, subCategoryId: selectedSubCategory };
         await axiosInstance.post(`/supply/attributes/link/`, data);
      } catch (error) {
         console.error(`Une erreur est survenue lors de la enchaînement`, error);
      }
      setShowModal(false);
   }

   // Handle create or update operations for attributes
   const handleCreateOrUpdate = async () => {
      const url = modalMode === 'create'
         ? '/supply/attributes'
         : `/supply/attributes/${currentAttribute.id}`;
      const method = modalMode === 'create' ? 'POST' : 'PUT';

      const data = {
         ...currentAttribute,
         values: modalMode === 'create' ? attributeValuesItems : currentAttribute.values,
         deletedAttributes: modalMode === 'update' ? deletedAttribute : null,
      };
      try {
         await axiosInstance({ method, url, data });
         fetchAttributes(); // Refresh the attribute list
      } catch (error) {
         console.error(`Une erreur est survenue lors de la ${modalMode === 'create' ? 'création' : 'mise à jour'} de l\'attribute:`, error);
      }
      setShowModal(false);
   };

   // Handle deletion of an attribute
   const handleDelete = async () => {
      try {
         await axiosInstance.delete(`/supply/attributes/${currentAttribute.id}`);
         fetchAttributes(); // Refresh the category list
      } catch (error) {
         console.error('Une erreur est survenue lors de la suppression de l\'attribute:', error);
      }
      setShowModal(false);
   };

   // Fetch the list of attributes
   const fetchAttributes = async () => {
      try {
         const response = await axiosInstance.get('/supply/attributes');
         setAttributes(response.data.data);
      } catch (error) {
         console.error('Une erreur est survenue lors de la récupération des attribues:', error);
      }
   }

   // Fetch the list of categories along with their subcategories
   const fetchCategories = async () => {
      try {
         const response = await axiosInstance.get('/supply/categories/subcategories');
         setCategories(response.data.data);
      } catch (error) {
         console.error('Une erreur est survenue lors de la récupération des attribues:', error);
      }
   }

   // Fetch the linked values of a subcategory for an attribute
   const fetchSubCategoriesValues = async (attributeId, subcategoryId) => {
      try {
         const response = await axiosInstance.get(`/supply/attributes/${attributeId}/${subcategoryId}`);
         const linkedValues = response.data.data;
         setCurrentAttribute(prev => ({
            ...prev,
            values: prev.values.map(value => ({
               ...value,
               linked: linkedValues.find(lv => lv.attributeValueId === value.id)?.linked || false
            }))
         }));
         setLoadingValues(false);
      } catch (error) {
         console.error('Une erreur est survenue lors de la récupération des attributes liee a subcategory:', error);
      }
   }

   useEffect(() => {
      fetchAttributes();
      fetchCategories();
   }, [])
   return (
      <>
         <div className="card">
            <div className="d-flex align-items-end justify-content-end pt-3">
               <button
                  type="button"
                  className="btn btn-primary me-3"
                  onClick={() => toggleModal(null, 'create')}
               >
                  <i className="bx bx-plus"></i> Ajouter nouveau
               </button>
            </div>
            <div className="table-responsive text-nowrap">
               <table className="table">
                  <thead>
                     <tr>
                        <th>Attributs</th>
                        <th>Valeurs</th>
                        <th>Actions</th>
                     </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                     {attributes.length > 0 ? attributes.map((attribute) => (
                        <tr key={attribute.id + '_' + attribute.name}
                           style={{ listStylePosition: 'outside' }}
                        >
                           <td>{attribute.name}</td>
                           <td>
                              <ul style={{ padding: 0, margin: 0 }}>
                                 {attribute.values ? attribute.values.map((value) => (
                                    <li key={value.value + '_' + value.attributevalueID}>{value.value}</li>
                                 )) : <li>Auccun Valeur trouvée</li>}
                              </ul>
                           </td>
                           <td>

                              <div className="d-inline-block text-nowrap">
                                 <button className="btn btn-sm btn-icon" onClick={() => toggleModal(attribute, 'link')} title='link'>
                                    <i className='bx bx-link-alt'></i>
                                 </button>
                                 <button className="btn btn-sm btn-icon" onClick={() => toggleModal(attribute, 'update')} title="Modifier">
                                    <i className="bx bx-edit"></i>
                                 </button>
                                 <button className="btn btn-sm btn-icon" onClick={() => toggleModal(attribute, 'delete')} title="Supprimer">
                                    <i className="bx bx-trash"></i>
                                 </button>
                              </div>
                           </td>
                        </tr>
                     )) : <tr>
                        <td>Auccun attribut trouvée</td>
                     </tr>}

                  </tbody>
               </table>
            </div>
         </div>

         {/* Modal for attribute management */}
         <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
               <Modal.Title>
                  {modalMode === 'create' ? 'Ajouter Attribue' : modalMode === 'update' ? 'Modifier Attribue' : modalMode === 'link' ? 'Link to subCategories' : 'Supprimer Attribue'}
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               {modalMode === 'delete' ? (
                  <p>Êtes-vous sûr de vouloir supprimer cette attribut?</p>
               ) : modalMode === 'create' ? (
                  //* Modal for creating the the attribute
                  <div>
                     <div className="form-group mt-2">
                        <label htmlFor="schoolNameFr">Nom :</label>
                        <input
                           type="text"
                           className="form-control"
                           id="schoolNameFr"
                           placeholder="Nom d'attribute"
                           value={currentAttribute?.name || ''}
                           onChange={(e) => setCurrentAttribute({ ...currentAttribute, name: e.target.value })}
                        />
                     </div>
                     <div className='form-group mt-2'>
                        <label htmlFor="values">Valeurs</label>
                        {attributeValuesItems ? attributeValuesItems.map((value, index) => (
                           <div key={value.key} className='form-group d-flex mt-2'>
                              <input
                                 type="text"
                                 className="form-control  dol-10"
                                 id="schoolNameFr"
                                 placeholder="Valeur"
                                 value={attributeValuesItems[index]?.value || ''}
                                 onChange={(e) => { handleAttributeValueChange(index, e) }}
                              />
                              <div className='d-flex align-items-center'>
                                 <i className="bx bx-x fs-4 text-muted cursor-pointer" data-repeater-delete onClick={() => { handleDeleteAttributeValueItem(index) }}
                                 ></i>
                              </div>
                           </div>
                        )) : ''}
                     </div>
                     <Button className='mt-2' onClick={handleAddAttributeValueItem}>Add value</Button>
                  </div>
               ) : modalMode === 'link' ? (
                  //* Modal to link the attribute to a subcategorie 
                  <>
                     <div>
                        <div className='form-group mt-'>
                           <label htmlFor="category">Choisie Category</label>
                           <select name="categories" id="categories" className='form-select' value={selectedCategory.id} onChange={(e) => handleCategoryChange(e.target.value)}>
                              <option value="-1">Choisie un category</option>
                              {categories ? categories.map((category) => (
                                 <option className='' key={category.id + '_' + category.name} value={category.id}>{category.name}</option>
                              )) : ''}
                           </select>
                        </div>
                        {isCategorySelected && (
                           <div className='form-group mt-2'>
                              <label htmlFor="category">Choisie subCategory</label>
                              <select name="categories" id="categories" className='form-select' value={selectedSubCategory} onChange={(e) => { setLoadingValues(true); handleSubCategoryChange(e.target.value) }}>
                                 <option value="-1">Choisie un category</option>
                                 {subCategories ? subCategories.map((subCategory) => (
                                    <option className='' key={subCategory.id + '_' + subCategory.name} value={subCategory.id}>{subCategory.name}</option>
                                 )
                                 )
                                    : ''}
                              </select>
                           </div>
                        )}

                        <div className="form-group mt-2">
                           <h4>Attribue Selectée</h4>
                           <p className='mb-1'><b className='font-bold'>Nom :</b> {currentAttribute.name}</p>
                        </div>
                        <div className='mt-0'>
                           <p><b>Valeurs :</b></p>
                           <div className='row position-relative'>
                              <div
                                 className={`col-12 pt-2 position-absolute z-1 h-100 text-center ${loadingValues ? 'visible' : 'invisible'}`}
                                 style={{ height: '100%', backgroundColor: "rgba(255, 255, 255, 0.4)", backdropFilter: "blur(5px)", }}>
                                 {selectedSubCategory == '-1' ? 'Select a subCategory' : 'Loading'}
                              </div>
                              {currentAttribute ? currentAttribute.values.map((value, index) => {
                                 if (value.isDeleted != true) {
                                    return (
                                       <div className='col-4 d-flex align-items-center fs-5 ' key={`${value.id}_${generateUniqueKey()}`}>
                                          <input
                                             className='w-4'
                                             type='checkbox'
                                             id={`${value.value}_${value.id}`}
                                             name={`${value.value}_${value.id}`}
                                             // value={value.id}
                                             checked={value.linked}
                                             onChange={() => { handleCheckboxChange(value.id) }}
                                             key={`${value.id}_${value.value}`}
                                          />
                                          <label htmlFor={`${value.value}_${value.id}`} className='ps-2'>{value.value}</label>
                                       </div>
                                    )
                                 }
                              }) : ''}
                           </div>
                        </div>
                     </div>
                  </>
               ) : (
                  //* modal for updating the  
                  <div>
                     <div className="form-group">
                        <label htmlFor="schoolNameFr">Nom :</label>
                        <input
                           type="text"
                           className="form-control"
                           id="schoolNameFr"
                           placeholder="Nom d'attribute"
                           value={currentAttribute?.name || ''}
                           onChange={(e) => setCurrentAttribute({ ...currentAttribute, name: e.target.value })}
                        />
                     </div>
                     <div className='form-group mt-2'>

                        <label htmlFor="values">Valeurs</label>
                        {currentAttribute.values ? currentAttribute.values.map((value, index) => (
                           <div key={`${value.id}_${value.value}`} className='form-group d-flex mt-2'>
                              <input
                                 type="text"
                                 className="form-control  dol-10"
                                 id="schoolNameFr"
                                 placeholder="Valeur"
                                 value={value?.value || ''}
                                 onChange={(e) => handleChangeCurrentAttributeValue(index, e)}
                              />
                              <div className='d-flex align-items-center'>
                                 <i className="bx bx-x fs-4 text-muted cursor-pointer" data-repeater-delete onClick={() => { handleDeleteCurrentAttributeValueItem(index) }}
                                 ></i>
                              </div>
                           </div>
                        )) : ''}
                     </div>
                     <Button className='mt-2' onClick={handleAddCurrentAttributeValue}>Add value</Button>
                  </div>
               )}
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={() => setShowModal(false)}>
                  Fermer
               </Button>
               {modalMode === 'delete' ? (
                  <Button variant="danger" onClick={handleDelete}>
                     Supprimer
                  </Button>
               ) : modalMode === 'link' ? (
                  <Button variant="primary" onClick={handleLink}>
                     Confirmer
                  </Button>
               ) : (
                  <Button variant="primary" onClick={handleCreateOrUpdate}>
                     {modalMode === 'create' ? 'Ajouter' : 'Modifier'}
                  </Button>
               )}
            </Modal.Footer>
         </Modal>
      </>

   )
}
