import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/web_logo_dark.png';
import '../index.css';
import AuthContext from '../context/AuthContext'; // Import the AuthContext
import { hasRole } from '../utils/roleUtils'; // Import the utility function for checking roles

const Sidebar = ({ isSidebarVisible, toggleSidebar }) => {
  const { user, isAuthenticated } = useContext(AuthContext); // Get roles and authentication status from context
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Function to check if the screen size is mobile or tablet
  const checkScreenSize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  // Add event listener on window resize
  useEffect(() => {
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  // Function to handle link clicks on mobile
  const handleLinkClick = () => {
    if (isMobile) {
      toggleSidebar();
    }
  };

  if (!isAuthenticated) {
    return null; // Don't render sidebar if not authenticated
  }
  

  return (
    <>
      <aside
        id="layout-menu"
        className={`layout-menu menu-vertical menu bg-menu-theme ${isMobile ? (isSidebarVisible ? 'open' : 'closed') : 'open'}`}
      >
        <div className="app-brand demo d-flex align-items-center mt-4 mb-4">
          <Link to="/" className="app-brand-link" onClick={handleLinkClick}>
            <img src={logo} className="img-fluid" alt="Logo" width="130" />
          </Link>
          {isMobile && (
            <a
              href="javascript:void(0);"
              className="layout-menu-toggle menu-link text-large ms-auto"
              onClick={toggleSidebar}
            >
              <i className="bx bx-chevron-left bx-sm align-middle"></i>
            </a>
          )}
        </div>

        {/* Close icon only visible on mobile */}
        {isMobile && isSidebarVisible && (
          <span
            className="close-icon"
            onClick={toggleSidebar}
            style={{
              position: 'absolute',
              top: '15px',
              right: '15px',
              cursor: 'pointer',
              zIndex: 1000,
            }}
          >
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '30px',
              height: '30px',
              backgroundColor: 'SlateBlue',
              borderRadius: '50%',
              cursor: 'pointer',
              marginTop: '23px'
            }}>
              <i className="bx bx-chevron-left bx-sm align-middle" style={{ fontSize: '24px', color: 'white' }}></i>
            </div>
          </span>
        )}

        <div className="menu-inner-shadow"></div>
        <ul className="menu-inner py-1">
          <li className="menu-item">
            <Link to="/" className="menu-link" onClick={handleLinkClick}>
              <i className="menu-icon tf-icons bx bx-home-circle"></i>
              <div className="text-truncate">Accueil</div>
            </Link>
          </li>
          {/* Conditionally render links based on roles */}
          {hasRole(user.roles, 'ADMIN') && (
          <>
            <li className="menu-item">
              <Link to="/admin/orders" className="menu-link" onClick={handleLinkClick}>
                <i className="menu-icon tf-icons bx bx-list-ul"></i>
                <div className="text-truncate">Orders</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/contactUs" className="menu-link" onClick={handleLinkClick}>
                <i className='bx bxs-contact'></i>
                <div className="text-truncate ms-2">Contact Us</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/donations" className="menu-link" onClick={handleLinkClick}>
                <i className='bx bx-donate-heart'></i>
                <div className="text-truncate ms-2">Donations</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/partnerships" className="menu-link" onClick={handleLinkClick}>
                <i className='bx bx-group'></i>
                <div className="text-truncate ms-2">Partnerships</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/schools" className="menu-link" onClick={handleLinkClick}>
                <i className="menu-icon tf-icons bx bx-building-house"></i>
                <div className="text-truncate">Schools</div>
              </Link>
            </li>
          </>
        )}
        {/* Conditionally render links based on roles */}
        {hasRole(user.roles, 'ITEMS_MANAGER') && (
          <>
            <li className="menu-item">
            <Link to="/admin/categories" className="menu-link" onClick={handleLinkClick}>
              <i className='menu-icon tf-icons bx bx-list-ul'></i>
              <div className="text-truncate">Categories</div>
            </Link>
          </li>
            <li className="menu-item">
            <Link to="/admin/attributes" className="menu-link" onClick={handleLinkClick}>
              <i className='menu-icon tf-icons bx bx-list-ul'></i>
              <div className="text-truncate">Attribues</div>
            </Link>
          </li>
          </>
        )}

        {/* Conditionally render links based on roles */}
        {hasRole(user.roles, 'LIVREUR') && (
          <>
            <li className="menu-item">
              <Link to="/admin/livreur" className="menu-link" onClick={handleLinkClick}>
                <i className='menu-icon tf-icons bx bx-list-ul'></i>
                <div className="text-truncate">Livreur - orders</div>
              </Link>
            </li>
          </>
        )}

        {/* Conditionally render links based on roles */}
        {hasRole(user.roles, 'SUPER_ADMIN') && (
          <>
            <li className="menu-item">
              <Link to="/admin/users" className="menu-link" onClick={handleLinkClick}>
              <i class='bx bxs-user'></i>
                <div className="text-truncate ms-3">users</div>
              </Link>
            </li>
          </>
        )}

          <li className="menu-header small text-uppercase"><span className="menu-header-text">Misc</span></li>
          <li className="menu-item">
            <a target="_blank" className="menu-link" onClick={handleLinkClick}>
              <i className="menu-icon tf-icons bx bx-support"></i>
              <div className="text-truncate">Support</div>
            </a>
          </li>
          <li className="menu-item">
            <a target="_blank" className="menu-link" onClick={handleLinkClick}>
              <i className="menu-icon tf-icons bx bx-file"></i>
              <div className="text-truncate">Documentation</div>
            </a>
          </li>
          <li className="menu-header small text-uppercase"><span className="menu-header-text">Compte</span></li>
          <li className="menu-item">
            <Link to="/profile/edit" className="menu-link" onClick={handleLinkClick}>
              <i className="bx bx-cog me-2"></i>
              <span className="align-middle">Paramètres</span>
            </Link>
          </li>
        </ul>
      </aside>
      {/* Overlay for mobile */}
      {isMobile && isSidebarVisible && (
        <div
          className="layout-overlay"
          onClick={toggleSidebar}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999,
          }}
        ></div>
      )}
    </>
  );
};

export default Sidebar;
