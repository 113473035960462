import React, { useEffect, useState } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { Modal, Button, Spinner } from 'react-bootstrap';
import myImage from '../images/1.png';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for react-toastify

const statusColors = {
    'Pending': 'orange',
    'Confirmed': 'blue',
    'Processing': 'yellow',
    'Ready for Packaging': 'violet',
    'Packaged': 'cyan',
    'Shipped': 'teal',
    'Out for Delivery': 'purple',
    'Delivered': 'green',
    'Completed': 'lightgreen',
    'Cancelled': 'grey',
    'Returned': 'pink',
    'Refunded': 'lightcoral',
    'Failed': 'red',
    'Awaiting Payment': 'blue'
};

function Livreur() {
    const [orders, setOrders] = useState([]);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState('');
    const [orderDate, setOrderDate] = useState('');
    const [packType, setPackType] = useState('');
    const [packTypes, setPackTypes] = useState([]);
    const [getstatus, setGetStatus] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showLivreurDetailsModal, setShowLivreurDetailsModal] = useState(false);
    const [newStatus, setNewStatus] = useState('');
    const [orderDetails, setOrderDetails] = useState(null);
    const [statistics, setStatistics] = useState(null);
    const [showImageModal, setShowImageModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [storeSeller, setStoreSeller] = useState({});
    const [commune, setCommune] = useState('');
    const [communes, setCommunes] = useState([]);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [selectedStatusId, setSelectedStatusId] = useState('');
    const [filteredCategories, setFilteredCategories] = useState([]);
    const itemsPerPage = 10;
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');


    // Fetch Pack Types with error handling
    const fetchPackTypes = async () => {
        try {
            const response = await axiosInstance.get('/pack-types');
            setPackTypes(response.data);
        } catch (error) {
            console.error('Error fetching pack types:', error);
            setError('Failed to fetch pack types.');
        }
    };

    // Fetch Communes with error handling
    const fetchCommunes = async () => {
        try {
            const response = await axiosInstance.get('/communes-name');
            setCommunes(response.data);
        } catch (error) {
            console.error('Error fetching pack types:', error);
            setError('Failed to fetch pack types.');
        }
    };

    // Fetch Statuses with error handling
    const fetchStatus = async () => {
        try {
            const response = await axiosInstance.get('/status');
            setGetStatus(response.data);
        } catch (error) {
            console.error('Error fetching status:', error);
            setError('Failed to fetch status.');
        }
    };

    // Fetch Orders with error handling
    const fetchOrders = async (page = 1) => {
        setLoading(true);
        setError('');
        try {
            const response = await axiosInstance.post('/livreur/orders', {
                page,
                status,
                orderDate,
                packTypeId: packType,
            });
            setOrders(response.data.data);
            setFilteredCategories(response.data.data);
            setCurrentPage(response.data.meta.current_page);
            setLastPage(response.data.meta.last_page);
        } catch (error) {
            setError('Failed to fetch orders.');
            console.error('Error fetching orders:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch Order Details only if not already fetched
    const fetchOrderDetails = async (orderId) => {
        try {
            const orderResponse = await axiosInstance.get(`/order/${orderId}`);
            setOrderDetails(orderResponse.data.data);
            if (orderResponse.data.data.communeId) {
                await getCommuneDetail(orderResponse.data.data.communeId);
            }
            if (orderResponse.data.data.storeSellerId) {
                await getStoreSeller(orderResponse.data.data.storeSellerId);
            }
        } catch (error) {
            console.error('Error fetching order details:', error);
            setError('Failed to fetch order details.');
        }
    };







    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setShowImageModal(true);
    };

    const handleImageModalClose = () => {
        setShowImageModal(false);
    };



    useEffect(() => {
        const fetchOrderStatistics = async () => {
            try {
                const response = await axiosInstance.get(`/livreur/orderStatistics`);
                setStatistics(response.data.data);
            } catch (error) {
                setError(error);
            }
        };

        fetchOrderStatistics();
    }, []);

    useEffect(() => {
        fetchPackTypes();
        fetchCommunes();
        fetchStatus();
    }, []);


    useEffect(() => {
        fetchOrders(currentPage);
    }, [currentPage, status, orderDate, packType]);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= lastPage) {
            setCurrentPage(page);
        }
    };

    const handleStatusChange = async (orderId, statusId) => {
        try {
            setLoading(true);
            const response = await axiosInstance.post(`/order/${orderId}/update-status`, {
                status: statusId,
            });
    
            if (response.data.status === 'success') {
                setShowSuccessModal(true);
                fetchOrders(currentPage);
            } else if (response.status === 403 && response.data.status === 'error') {
                setLoading(false);
                setShowConfirmationModal(false);
                setErrorMessage(response.data.message || 'Unauthorized action.');
                setShowErrorModal(true);
            } else {
                setError('Failed to update order status.');
            }
        } catch (error) {
            setLoading(false);
            setShowConfirmationModal(false);
    
            if (error.response && error.response.status === 403) {
                setErrorMessage(error.response.data.message || 'Unauthorized action.');
                setShowErrorModal(true);
            } else {
                setError('An error occurred while updating the order status.');
                console.error('Error updating order status:', error);
            }
        } finally {
            setLoading(false);
            setShowConfirmationModal(false);
        }
    };
    const handleStatusChangeClick = (orderId, statusId) => {
        const selectedOrder = orders.find(order => order.id === orderId);
        setSelectedOrderId(orderId);
        setSelectedStatusId(statusId);
        setNewStatus(statusId);
        setShowConfirmationModal(true);
    };
    const handleConfirmStatusChange = () => {
        handleStatusChange(selectedOrderId, newStatus);
    };

    const handleModalClose = () => {
        setShowSuccessModal(false);
        setShowLivreurDetailsModal(false);
        setShowConfirmationModal(false);
        setNewStatus('');
        setOrderDetails(null);
        setStoreSeller({})
        setCommune({});
    };


    const handleLivreurDetailsClick = async (orderId) => {
        setShowLivreurDetailsModal(true);

        // Check if order details are already in state
        if (!orderDetails || orderDetails.id !== orderId) {
            await fetchOrderDetails(orderId);
        }
    };


    const getCommuneName = (communeId) => {
        const commune = communes.find(c => c.id === communeId);
        return commune ? commune.name : 'Unknown';
    };

    const getStatusName = (statusId) => {
        const status = getstatus.find(st => st.id === statusId);
        return status ? status.name : 'Unknown';
    };

    const getStatusColor = (statusId) => {
        const status = getstatus.find(st => st.id === statusId); if (status) {
            return statusColors[status.name] || 'grey';
        }
        return 'grey';
    };


    const getStoreSeller = async (storeId) => {
        try {
            const response = await axiosInstance.get(`/getStoreSeller/${storeId}`);
            setStoreSeller(response.data.data);
        } catch (error) {
            console.error('Error fetching school details:', error);
        }
    };
    const getCommuneDetail = async (communeId) => {
        try {
            const response = await axiosInstance.get(`/getCommune/${communeId}`);
            setCommune(response.data.data);
        } catch (error) {
            console.error('Error fetching commune details:', error);
        }
    };
    // pagination
    const getPaginatedData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return filteredCategories.slice(startIndex, endIndex);
    };

    const totalPages = Math.ceil(filteredCategories.length / itemsPerPage);

    const renderPagination = () => {
        const pages = [];

        if (lastPage <= 4) {
            // Case when the total number of pages is less than or equal to 4
            for (let i = 1; i <= lastPage; i++) {
                pages.push(
                    <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
                        <button className="page-link" onClick={() => handlePageChange(i)}>
                            {i}
                        </button>
                    </li>
                );
            }
        } else if (currentPage === 1) {
            // Case for the first page, showing the first 4 pages and adding ellipsis if there are more pages
            for (let i = 1; i <= 4; i++) {
                pages.push(
                    <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
                        <button className="page-link" onClick={() => handlePageChange(i)}>
                            {i}
                        </button>
                    </li>
                );
            }
            if (lastPage > 4) {
                pages.push(
                    <li className="page-item disabled" key="ellipsis1">
                        <span className="page-link">...</span>
                    </li>
                );
                for (let i = lastPage - 2; i <= lastPage; i++) {
                    pages.push(
                        <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
                            <button className="page-link" onClick={() => handlePageChange(i)}>
                                {i}
                            </button>
                        </li>
                    );
                }
            }
        } else if (currentPage === lastPage) {
            // Case for the last page, showing the last 4 pages
            for (let i = lastPage - 3; i <= lastPage; i++) {
                pages.push(
                    <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
                        <button className="page-link" onClick={() => handlePageChange(i)}>
                            {i}
                        </button>
                    </li>
                );
            }
        } else {
            // Case for middle pages, showing 1 previous page, current page, and 2 next pages
            const start = Math.max(currentPage - 1, 1);  // Show one previous page
            const end = Math.min(currentPage + 2, lastPage);  // Show two pages after the current one

            if (start > 1) {
                pages.push(
                    <li className="page-item disabled" key="ellipsis1">
                        <span className="page-link">...</span>
                    </li>
                );
            }

            for (let i = start; i <= end; i++) {
                pages.push(
                    <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
                        <button className="page-link" onClick={() => handlePageChange(i)}>
                            {i}
                        </button>
                    </li>
                );
            }

            if (end < lastPage) {
                pages.push(
                    <li className="page-item disabled" key="ellipsis2">
                        <span className="page-link">...</span>
                    </li>
                );
            }
        }

        return (
            <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(1)}
                        disabled={currentPage === 1}
                    >
                        Premier
                    </button>
                </li>

                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                </li>

                {pages}

                <li className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === lastPage}
                    >
                        Next
                    </button>
                </li>

                <li className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(lastPage)}
                        disabled={currentPage === lastPage}
                    >
                        Dernier
                    </button>
                </li>
            </ul>
        );
    };

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <div className="layout-page">
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="py-3 mb-4">
                            <span className="text-muted fw-light">Clients /</span> Liste des Commandes
                        </h4>

                        <div class="card mb-4">
                            <div class="card-widget-separator-wrapper">
                                <div class="card-body card-widget-separator">
                                    <div class="row gy-4 gy-sm-1">
                                        <div class="col-sm-6 col-lg-3">
                                            <div class="d-flex justify-content-between align-items-start card-widget-1 border-end pb-3 pb-sm-0">
                                                <div>
                                                    <h6 class="mb-2">Total des Commandes</h6>
                                                    <h4 class="mb-2">{statistics?.total_orders?.count ?? 0}</h4>
                                                    <p class="mb-0">
                                                        <span class="text-muted me-2">
                                                            {statistics?.total_orders?.revenue ?? 0}$
                                                        </span>
                                                        <span class="badge bg-label-success"></span>
                                                    </p>

                                                </div>
                                                <div class="avatar me-sm-4">
                                                    <span class="avatar-initial rounded bg-label-secondary">
                                                        <i class="bx bx-shopping-bag bx-sm"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            {/* <hr class="d-none d-sm-block d-lg-none me-4"> */}
                                        </div>
                                        <div class="col-sm-6 col-lg-3">
                                            <div class="d-flex justify-content-between align-items-start card-widget-2 border-end pb-3 pb-sm-0">
                                                <div>
                                                    <h6 class="mb-2">Commandes en Cours</h6>
                                                    <h4 class="mb-2">{statistics?.orders_in_progress?.count ?? 0}</h4>
                                                    <p class="mb-0">
                                                        <span class="text-muted me-2">
                                                            {statistics?.orders_in_progress?.count ?? 0}$
                                                        </span>
                                                        <span class="badge bg-label-success"></span>
                                                    </p>

                                                </div>
                                                <div class="avatar me-lg-4">
                                                    <span class="avatar-initial rounded bg-label-secondary">
                                                        <i class="bx bx-hourglass bx-sm"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            {/* <hr class="d-none d-sm-block d-lg-none"> */}
                                        </div>
                                        <div class="col-sm-6 col-lg-3">
                                            <div class="d-flex justify-content-between align-items-start border-end pb-3 pb-sm-0 card-widget-3">
                                                <div>
                                                    <h6 class="mb-2">Commandes Terminées</h6>
                                                    <h4 class="mb-2">{statistics?.completed_orders?.count ?? 0}</h4>
                                                    <p class="mb-0 text-muted">{statistics?.completed_orders?.revenue ?? 0}$</p>

                                                </div>
                                                <div class="avatar me-sm-4">
                                                    <span class="avatar-initial rounded bg-label-secondary">
                                                        <i class="bx bx-check-circle bx-sm"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-lg-3">
                                            <div class="d-flex justify-content-between align-items-start">
                                                <div>
                                                    <h6 class="mb-2">Valeur Moyenne de la Commande</h6>
                                                    <h4 class="mb-2">{statistics?.average_order_value ?? 0}</h4>
                                                    <p class="mb-0">
                                                        <span class="text-muted me-2"></span>
                                                        <span class="badge bg-label-danger"></span>
                                                    </p>

                                                </div>
                                                <div class="avatar">
                                                    <span class="avatar-initial rounded bg-label-secondary">
                                                        <i class="bx bx-bar-chart bx-sm"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header">
                                <h5 className="d-inline-block">Filtres de Recherche</h5>
                            </div>
                            <div className="card-body">
                                <div className="row gy-3">
                                    <div className="col-md-4">
                                        <label htmlFor="status" className="form-label">Statut de la Commande</label>
                                        <select id="status" className="form-select" value={status} onChange={(e) => setStatus(e.target.value)}>
                                            <option value="">All</option>
                                            {getstatus.map((status) => (
                                                <option key={status.id} value={status.id}>{status.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="orderDate" className="form-label">Date de la Commande</label>
                                        <input type="date" id="orderDate" className="form-control" value={orderDate} onChange={(e) => setOrderDate(e.target.value)} />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="packType" className="form-label">Type de Pack</label>
                                        <select id="packType" className="form-select" value={packType} onChange={(e) => setPackType(e.target.value)}>
                                            <option value="">All</option>
                                            {packTypes.map((packType) => (
                                                <option key={packType.id} value={packType.id}>{packType.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card mt-3">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Order Date</th>
                                            <th>commune name</th>
                                            <th>Buyer Phone Number</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            <tr>
                                                <td colSpan="7" className="text-center">Chargement...</td>
                                            </tr>
                                        ) : orders.length === 0 ? (
                                            <tr>
                                                <td colSpan="7" className="text-center">Aucune commande trouvée</td>
                                            </tr>
                                        ) : orders.map((order) => (
                                            <tr key={order.id}>
                                                <td>{new Intl.DateTimeFormat('fr-FR', {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit'
                                                }).format(new Date(order.created_at))}
                                                </td>
                                                <td><p>{getCommuneName(order.communeId)}</p></td>
                                                <td>{order.buyerPhoneNumber}</td>
                                                <td>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        padding: '5px 10px',
                                                        color: 'white',
                                                        backgroundColor: getStatusColor(order.status),
                                                        borderRadius: '5px'
                                                    }}>
                                                        {getStatusName(order.status)}
                                                    </span>
                                                </td>
                                                <div className="d-flex align-items-center">
                                                    <select
                                                        className="form-select me-2 sel"
                                                        value={order.status_id}
                                                        onChange={(e) => handleStatusChangeClick(order.id, e.target.value)}
                                                    >
                                                        <option value="" disabled selected>Select Status</option>
                                                        {getstatus
                                                            .filter(st => [7, 6, 8, 15, 10].includes(st.id))
                                                            .map(st => (
                                                                <option key={st.id} value={st.id}>
                                                                    {st.name}
                                                                </option>
                                                            ))}
                                                    </select>
                                                    <button
                                                        className="btn btn-sm btn-primary"
                                                        onClick={() => handleLivreurDetailsClick(order.id)}
                                                    >
                                                        Details
                                                    </button>
                                                </div>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="row mx-2 mt-4 justify-content-end">
                                <div className="col-sm-12 col-md-6 text-right">
                                    <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                                        Affichage de {((currentPage - 1) * itemsPerPage) + 1} à {Math.min(currentPage * itemsPerPage, filteredCategories.length)} sur {filteredCategories.length} entrées
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                                        {renderPagination()}
                                    </div>
                                </div>
                            </div>
                        </div>



                        {/* Success Modal */}
                        <Modal show={showSuccessModal} onHide={handleModalClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Statut Mis à Jour</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                L'opération est passé avec succées
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={handleModalClose}>
                                    Fermer
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* Error Modal */}
                        <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Erreur de Mise à Jour</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {errorMessage}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={() => setShowErrorModal(false)}>
                                    Fermer
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* Confirmation Modal */}
                        <Modal show={showConfirmationModal} onHide={handleModalClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Confirmation de Changement de Statut</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {loading ? (
                                    <div className="text-center">
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>
                                ) : (
                                    <p>Are you sure you want to change the status </p>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                {!loading && (
                                    <>
                                        <Button variant="secondary" onClick={handleModalClose}>Cancel</Button>
                                        <Button variant="primary" onClick={handleConfirmStatusChange}>Confirm</Button>
                                    </>
                                )}
                            </Modal.Footer>

                        </Modal>

                        {/* Livreur Details Modal */}
                        <Modal show={showLivreurDetailsModal} onHide={handleModalClose} size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>Détails du livreur</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {orderDetails ? (
                                    <div>
                                        <h5>Détails d'acheteur</h5>
                                        <p><strong> Nom :</strong> {orderDetails.buyerName}</p>
                                        <p><strong> numéro de teléphone :</strong> {orderDetails.buyerPhoneNumber}</p>
                                        <hr />
                                        <h5>Adresse de livraison</h5>
                                        <p><strong> adresse :</strong> {orderDetails.shippingAddress}</p>
                                        <hr />
                                        <h5>Informations sur la bibliothèque</h5>
                                        <p><strong> adresse :</strong> {storeSeller.addressFr || 'Loading...'}</p>
                                    </div>
                                ) : (
                                    <div className="text-center">Chargement des détails de la commande...</div>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleModalClose}>
                                    Fermer
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={showImageModal} onHide={() => setShowImageModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Image Preview</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <img
                                    src={selectedImage}
                                    alt="Enlarged Order Image"
                                    style={{ width: '100%', height: 'auto' }} // Full-width image
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleImageModalClose}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>


                        {error && <div className="alert alert-danger">{error}</div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Livreur;