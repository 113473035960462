import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';

const Partnerships = () => {
  const [partnerships, setPartnerships] = useState([]);
  const [filteredPartnerships, setFilteredPartnerships] = useState([]);
  const [filter, setFilter] = useState({
    created_at: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchPartnerships();
  }, []);

  // Fetch Partnerships data
  const fetchPartnerships = async () => {
    try {
      const response = await axiosInstance.get('/partnerships');
      const data = response.data.data;
      setPartnerships(data);
      setFilteredPartnerships(data);
    } catch (error) {
      console.error('Error fetching Partnerships data:', error);
      setPartnerships([]);
      setFilteredPartnerships([]);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter({
      ...filter,
      [name]: value
    });
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    const filtered = partnerships.filter(partnership => {
      const isDateMatch = filter.created_at ? new Date(partnership.created_at).toISOString().split('T')[0] === filter.created_at : true;
      return isDateMatch;
    });

    setFilteredPartnerships(filtered);
    setCurrentPage(1); // Reset to first page after filtering
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredPartnerships.slice(startIndex, endIndex);
  };

  const totalPages = Math.ceil(filteredPartnerships.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    const pages = [];
    const start = Math.max(currentPage - 2, 1);
    const end = Math.min(start + 4, totalPages);

    for (let i = start; i <= end; i++) {
      pages.push(
        <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }

    return (
      <div className="row mx-2 my-3">
        <div className="col-md-12 d-flex justify-content-end">
          <nav aria-label="Page navigation">
            <ul className="pagination justify-content-end mb-0">
              {/* First Button */}
              <li className={`page-item ${currentPage > 1 ? '' : 'disabled'}`}>
                <button className="page-link" onClick={() => handlePageChange(1)} disabled={currentPage <= 1}>
                  <i className="tf-icon bx bx-chevrons-left"></i> First
                </button>
              </li>

              {/* Previous Button */}
              <li className={`page-item ${currentPage > 1 ? '' : 'disabled'}`}>
                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage <= 1}>
                  <i className="tf-icon bx bx-chevron-left"></i> Previous
                </button>
              </li>

              {/* Page Numbers */}
              {pages}

              {/* Next Button */}
              <li className={`page-item ${currentPage < totalPages ? '' : 'disabled'}`}>
                <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage >= totalPages}>
                  Next <i className="tf-icon bx bx-chevron-right"></i>
                </button>
              </li>

              {/* Last Button */}
              <li className={`page-item ${currentPage < totalPages ? '' : 'disabled'}`}>
                <button className="page-link" onClick={() => handlePageChange(totalPages)} disabled={currentPage >= totalPages}>
                  Last <i className="tf-icon bx bx-chevrons-right"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  };

  const handleResetFilter = () => {
    setFilter({
      created_at: ''
    });
    setFilteredPartnerships(partnerships);
    setCurrentPage(1); // Reset to first page after resetting
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <div className="layout-page">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="py-3 mb-4">
              <span className="text-muted fw-light">Partnerships /</span> List
            </h4>
            <div className="card">
              <div className="card-datatable table-responsive">
                <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                  <div className="row mx-2 mb-2 mt-4">
                    <div className="col-md-12 mt-2">
                      <form onSubmit={handleFilterSubmit}>
                        <div className="row g-3">
                          <div className="col-md-2">
                            <label htmlFor="created_at" className="form-label">Date Created:</label>
                            <input
                              type="date"
                              id="created_at"
                              name="created_at"
                              className="form-control"
                              value={filter.created_at}
                              onChange={handleFilterChange}
                            />
                          </div>

                          <div className="d-flex align-items-end justify-content-end">
                            <button type="submit" className="btn btn-outline-secondary me-3">
                              <i className="bx bx-search"></i> Search
                            </button>
                            <button type="button" className="btn btn-outline-danger" onClick={handleResetFilter}>
                              <i className="bx bx-reset"></i> Réinitialiser le filtre
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <table
                    className="datatables-grades table border-top dataTable no-footer dtr-column"
                    id="DataTables_Table_0"
                    style={{ width: '100%' }}
                  >
                    <thead>
                      <tr>
                        <th>Organization Name</th>
                        <th>Type</th>
                        <th>Contact Person</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Message</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(filteredPartnerships) && getPaginatedData().length > 0 ? (
                        getPaginatedData().map((partnership) => (
                          <tr key={partnership.id}>
                            <td>{partnership.organization_name}</td>
                            <td>{partnership.type}</td>
                            <td>{partnership.contact_person}</td>
                            <td>{partnership.email}</td>
                            <td>{partnership.phone_number}</td>
                            <td>{partnership.message}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-center">
                            No partnerships found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="row mx-2 mt-4 justify-content-end">
                    <div className="col-sm-12 col-md-6 text-right">
                      <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                        Displaying {((currentPage - 1) * itemsPerPage) + 1} to {Math.min(currentPage * itemsPerPage, filteredPartnerships.length)} of {filteredPartnerships.length} entries
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                        {renderPagination()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content-backdrop fade"></div>
    </div>
  );
};

export default Partnerships;
