// import React, { useState, useEffect } from 'react';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
// import axiosInstance from '../utils/axiosInstance';

// const Schools = () => {
//   const [schools, setSchools] = useState([]);
//   const [communes, setCommunes] = useState([]);
//   const [filteredSchools, setFilteredSchools] = useState([]);
//   const [currentGrade, setCurrentGrade] = useState(null);
//   const [showModal, setShowModal] = useState(false);
//   const [modalMode, setModalMode] = useState(''); // 'create' or 'update' or 'delete'
//   const [filter, setFilter] = useState({
//     schoolNameFr: '',
//     schoolNameAr: '',
//     schoolAddressFr: '',
//     schoolAddressAr: '',
//     schoolContactNumber: '',
//     communeId: '',
//     isValide: ''
//   });
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 10;

//   useEffect(() => {
//     fetchSchools();
//     fetchCommunes();
//   }, []);


//  // Fetch Communes
// const fetchCommunes = async () => {
//   try {
//     const response = await axiosInstance.get('/communes');
//     const data = response.data.data; // Access the data array in the response
//     setCommunes(data);
//   } catch (error) {
//     console.error('An error occurred while fetching communes:', error);
//   }
// };

// // Fetch Schools
// const fetchSchools = async () => {
//   try {
//     const response = await axiosInstance.get('/schoolsdata');
//     const data = response.data.data; // Access the data array in the response
//     setSchools(data);
//     setFilteredSchools(data);
//   } catch (error) {
//     console.error('An error occurred while fetching schools:', error);
//     setSchools([]);
//     setFilteredSchools([]);
//   }
// };

//   const handleFilterChange = (e) => {
//     const { name, value } = e.target;
//     setFilter({
//       ...filter,
//       [name]: value
//     });
//   };

//   // const handleFilterSubmit = (e) => {
//   //   e.preventDefault();
//   //   setFilteredSchools(
//   //     schools.filter(school =>
//   //       (filter.schoolNameFr ? school.schoolNameFr.toLowerCase().includes(filter.schoolNameFr.toLowerCase()) : true) &&
//   //       (filter.schoolNameAr ? school.schoolNameAr.includes(filter.schoolNameAr) : true) &&
//   //       (filter.schoolAddressFr ? school.schoolAddressFr.includes(filter.schoolAddressFr) : true) &&
//   //       (filter.schoolAddressAr ? school.schoolAddressAr.includes(filter.schoolAddressAr) : true) &&
//   //       (filter.schoolContactNumber ? school.schoolContactNumber.includes(filter.schoolContactNumber) : true) &&
//   //       (filter.communeId ? school.communeId === parseInt(filter.communeId) : true) &&
//   //       (filter.isValide !== '' ? school.isValide === (filter.isValide === '1' ? true : false) : true)
//   //     )
//   //   );
//   //   setCurrentPage(1); // Reset to first page after filtering
//   // };

//   const handleFilterSubmit = (e) => {
//     e.preventDefault();
//     console.log('Filter State:', filter);
//     const filtered = schools.filter(school => {
//       const isNameFrMatch = filter.schoolNameFr ? school.schoolNameFr.toLowerCase().includes(filter.schoolNameFr.toLowerCase()) : true;
//       const isNameArMatch = filter.schoolNameAr ? school.schoolNameAr.includes(filter.schoolNameAr) : true;
//       const isAddressFrMatch = filter.schoolAddressFr ? school.schoolAddressFr.includes(filter.schoolAddressFr) : true;
//       const isAddressArMatch = filter.schoolAddressAr ? school.schoolAddressAr.includes(filter.schoolAddressAr) : true;
//       const isContactNumberMatch = filter.schoolContactNumber ? school.schoolContactNumber.includes(filter.schoolContactNumber) : true;
//       const isCommuneMatch = filter.communeId ? school.communeId === parseInt(filter.communeId) : true;
//       const isValideMatch = filter.isValide !== '' ? school.isValide.toString() === filter.isValide : true;
  
//       return isNameFrMatch && isNameArMatch && isAddressFrMatch && isAddressArMatch && isContactNumberMatch && isCommuneMatch && isValideMatch;
//     });

//     setFilteredSchools(filtered);
//     setCurrentPage(1); // Reset to first page after filtering
//   };
  
  

//   const getPaginatedData = () => {
//     const startIndex = (currentPage - 1) * itemsPerPage;
//     const endIndex = startIndex + itemsPerPage;
//     return filteredSchools.slice(startIndex, endIndex);
//   };

//   const totalPages = Math.ceil(filteredSchools.length / itemsPerPage);

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   const renderPagination = () => {
//     const pages = [];
//     const start = Math.max(currentPage - 2, 1);
//     const end = Math.min(start + 4, totalPages);
  
//     for (let i = start; i <= end; i++) {
//       pages.push(
//         <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
//           <button className="page-link" onClick={() => handlePageChange(i)}>
//             {i}
//           </button>
//         </li>
//       );
//     }
  
//     return (
//       <div className="">
//         <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
//           <nav aria-label="Page navigation">
//             <ul className="pagination justify-content-end">
//               {/* First Button */}
//               <li className={`page-item ${currentPage > 1 ? '' : 'disabled'}`} id="DataTables_Table_0_first">
//                 <button className="page-link" onClick={() => handlePageChange(1)} disabled={currentPage <= 1}>
//                   <i className="tf-icon bx bx-chevrons-left"></i> First
//                 </button>
//               </li>
  
//               {/* Previous Button */}
//               <li className={`page-item ${currentPage > 1 ? '' : 'disabled'}`} id="DataTables_Table_0_previous">
//                 <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage <= 1}>
//                   <i className="tf-icon bx bx-chevron-left"></i> Previous
//                 </button>
//               </li>
  
//               {/* Page Numbers */}
//               {pages}
  
//               {/* Next Button */}
//               <li className={`page-item ${currentPage < totalPages ? '' : 'disabled'}`} id="DataTables_Table_0_next">
//                 <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage >= totalPages}>
//                   Next <i className="tf-icon bx bx-chevron-right"></i>
//                 </button>
//               </li>
  
//               {/* Last Button */}
//               <li className={`page-item ${currentPage < totalPages ? '' : 'disabled'}`} id="DataTables_Table_0_last">
//                 <button className="page-link" onClick={() => handlePageChange(totalPages)} disabled={currentPage >= totalPages}>
//                   Last <i className="tf-icon bx bx-chevrons-right"></i>
//                 </button>
//               </li>
//             </ul>
//           </nav>
//         </div>
//       </div>
//     );
//   };
  

//   const toggleModal = (grade = null, mode = '') => {
//     setCurrentGrade(grade);
//     setModalMode(mode);
//     setShowModal(!showModal);
//   };

//   const handleCreateOrUpdate = async () => {
//     const url = modalMode === 'create'
//       ? '/schoolsdata'
//       : `/schoolsdata/${currentGrade.id}`;
//     const method = modalMode === 'create' ? 'POST' : 'PUT';
  
//     try {
//       await axiosInstance({
//         method,
//         url,
//         data: currentGrade,
//       });
//       fetchSchools(); // Refresh the school list
//     } catch (error) {
//       console.error(`An error occurred while ${modalMode === 'create' ? 'creating' : 'updating'} the grade:`, error);
//     }
//     setShowModal(false);
//   };
  
//   const handleDelete = async () => {
//     try {
//       await axiosInstance.delete(`/schoolsdata/${currentGrade.id}`);
//       fetchSchools(); // Refresh the school list
//     } catch (error) {
//       console.error('An error occurred while deleting the grade:', error);
//     }
//     setShowModal(false);
//   };
  

//   const handleResetFilter = () => {
//     setFilter({
//       schoolNameFr: '',
//       schoolNameAr: '',
//       schoolAddressFr: '',
//       schoolAddressAr: '',
//       schoolContactNumber: '',
//       communeId: '',
//       isValide: ''
//     });
//     setFilteredSchools(schools);
//     setCurrentPage(1); // Reset to first page after resetting
//   };
  

//   return (
//     <div className="layout-wrapper layout-content-navbar">
//       <div className="layout-container">
//         <div className="layout-page">
//           <div className="container-xxl flex-grow-1 container-p-y">
//             <h4 className="py-3 mb-4">
//               <span className="text-muted fw-light">Schools /</span> Liste
//             </h4>
//             <div className="card">
//               <div className="card-datatable table-responsive">
//                 <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
//                   <div className="row mx-2 mb-2 mt-4">
//                     <div className="col-md-12 mt-2">
//                       <form onSubmit={handleFilterSubmit}>
//                         <div className="row g-3">
//                         <div className="d-flex align-items-end justify-content-end">
//                             <button
//                                 type="button"
//                                 className="btn btn-primary me-3"
//                                 onClick={() => toggleModal(null, 'create')}
//                               >
//                                 <i className="bx bx-plus"></i> Add new
//                               </button>
//                           </div>
//                           <div className="col-md-2">
//                             <label htmlFor="isValide" className="form-label">Valide</label>
//                             <select
//                               id="isValide"
//                               name="isValide"
//                               className="form-control"
//                               value={filter.isValide}
//                               onChange={handleFilterChange}
//                             >
//                               <option value="">All</option>
//                               <option value="1">Valid</option>
//                               <option value="0">Invalid</option>
//                             </select>
//                           </div>
//                           <div className="col-md-2">
//                             <label htmlFor="schoolNameFr" className="form-label">Name (FR)</label>
//                             <input
//                               type="search"
//                               id="schoolNameFr"
//                               name="schoolNameFr"
//                               className="form-control"
//                               placeholder="Nom (FR)"
//                               value={filter.schoolNameFr}
//                               onChange={handleFilterChange}
//                             />
//                           </div>
//                           <div className="col-md-2">
//                             <label htmlFor="schoolNameAr" className="form-label">Name (AR)</label>
//                             <input
//                               type="search"
//                               id="schoolNameAr"
//                               name="schoolNameAr"
//                               className="form-control"
//                               placeholder="Nom (AR)"
//                               value={filter.schoolNameAr}
//                               onChange={handleFilterChange}
//                             />
//                           </div>
//                           <div className="col-md-2">
//                             <label htmlFor="schoolAddressFr" className="form-label">Addresse (FR)</label>
//                             <input
//                               type="search"
//                               id="schoolAddressFr"
//                               name="schoolAddressFr"
//                               className="form-control"
//                               placeholder="Adresse (FR)"
//                               value={filter.schoolAddressFr}
//                               onChange={handleFilterChange}
//                             />
//                           </div>
//                           <div className="col-md-2">
//                             <label htmlFor="schoolAddressAr" className="form-label">Addresse (AR)</label>
//                             <input
//                               type="search"
//                               id="schoolAddressAr"
//                               name="schoolAddressAr"
//                               className="form-control"
//                               placeholder="Adresse (AR)"
//                               value={filter.schoolAddressAr}
//                               onChange={handleFilterChange}
//                             />
//                           </div>
//                           <div className="col-md-2">
//                             <label htmlFor="schoolContactNumber" className="form-label">Contact</label>
//                             <input
//                               type="search"
//                               id="schoolContactNumber"
//                               name="schoolContactNumber"
//                               className="form-control"
//                               placeholder="Contact"
//                               value={filter.schoolContactNumber}
//                               onChange={handleFilterChange}
//                             />
//                           </div>
//                           <div className="col-md-2">
//                           <label htmlFor="communeId" className="form-label">Commune</label>
//                           <select
//                             id="communeId"
//                             name="communeId"
//                             className="form-control"
//                             value={filter.communeId}
//                             onChange={handleFilterChange}
//                           >
//                             <option value="">Select Commune</option>
//                             {communes.map((commune) => (
//                               <option key={commune.id} value={commune.id}>
//                                 {commune.name}
//                               </option>
//                             ))}
//                           </select>
//                         </div>

//                           <div className="d-flex align-items-end justify-content-end">
//                             <button type="submit" className="btn btn-outline-secondary me-3">
//                               <i className="bx bx-search"></i> Search
//                             </button>
//                             <button type="button" className="btn btn-outline-danger" onClick={handleResetFilter}>
//                               <i className="bx bx-reset"></i> Reset Filter
//                             </button>
//                           </div>
//                         </div>
//                       </form>
//                     </div>
//                   </div>
//                   <table
//                     className="datatables-grades table border-top dataTable no-footer dtr-column"
//                     id="DataTables_Table_0"
//                     style={{ width: '100%' }}
//                   >
//                     <thead>
//                       <tr>
//                         <th>Name Fr</th>
//                         <th>Name Ar</th>
//                         <th>Addresse Fr</th>
//                         <th>Addresse Ar</th>
//                         <th>Commune</th>
//                         <th>Numéro de Contact</th>
//                         <th>Site</th>
//                         <th>Map</th>
//                         <th>Actions</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {Array.isArray(filteredSchools) && getPaginatedData().length > 0 ? (
//                         getPaginatedData().map((school) => (
//                           <tr key={school.id}>
//                             <td>{school.schoolNameFr}</td>
//                             <td>{school.schoolNameAr}</td>
//                             <td>{school.schoolAddressFr}</td>
//                             <td>{school.schoolAddressAr}</td>
//                             <td>{school.commune ? school.commune.name : 'N/A'}</td>
//                             <td>{school.schoolContactNumber}</td>
//                             <td><a href={school.webSiteURL} target="_blank" rel="noopener noreferrer">Site</a></td>
//                             <td><a href={school.mapURL} target="_blank" rel="noopener noreferrer">Map</a></td>
//                             <td className="text-start">
//                               <div className="d-inline-block text-nowrap">
//                                 <button className="btn btn-sm btn-icon" onClick={() => toggleModal(school, 'update')} title="Modifier">
//                                   <i className="bx bx-edit"></i>
//                                 </button>
//                                 <button className="btn btn-sm btn-icon" onClick={() => toggleModal(school, 'delete')} title="Supprimer">
//                                   <i className="bx bx-trash"></i>
//                                 </button>
//                               </div>
//                             </td>
//                           </tr>
//                         ))
//                       ) : (
//                         <tr>
//                           <td colSpan="6">No schools found.</td>
//                         </tr>
//                       )}
//                     </tbody>
//                   </table>
//                   <div className="row mx-2 mt-4 justify-content-end">
//                     <div className="col-sm-12 col-md-6 text-right">
//                       <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
//                         Showing {((currentPage - 1) * itemsPerPage) + 1} to {Math.min(currentPage * itemsPerPage, filteredSchools.length)} of {filteredSchools.length} entries
//                       </div>
//                     </div>
//                     <div className="col-sm-12 col-md-6">
//                       <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
//                         {renderPagination()}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* Modal */}
//       <Modal show={showModal} onHide={() => setShowModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>
//             {modalMode === 'create' ? 'Ajouter Grade' : modalMode === 'update' ? 'Modifier Grade' : 'Supprimer Grade'}
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {modalMode === 'delete' ? (
//             <p>Êtes-vous sûr de vouloir supprimer ce grade?</p>
//           ) : (
//             <div>
//               <div className="form-group">
//                 <label htmlFor="schoolNameFr">Nom (FR) :</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="schoolNameFr"
//                   placeholder="Nom (FR)"
//                   value={currentGrade?.schoolNameFr || ''}
//                   onChange={(e) => setCurrentGrade({ ...currentGrade, schoolNameFr: e.target.value })}
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="schoolNameAr">Nom (AR) :</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="schoolNameAr"
//                   placeholder="Nom (AR)"
//                   value={currentGrade?.schoolNameAr || ''}
//                   onChange={(e) => setCurrentGrade({ ...currentGrade, schoolNameAr: e.target.value })}
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="schoolAddressFr">Adresse (FR) :</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="schoolAddressFr"
//                   placeholder="Adresse (FR)"
//                   value={currentGrade?.schoolAddressFr || ''}
//                   onChange={(e) => setCurrentGrade({ ...currentGrade, schoolAddressFr: e.target.value })}
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="schoolAddressAr">Adresse (AR) :</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="schoolAddressAr"
//                   placeholder="Adresse (AR)"
//                   value={currentGrade?.schoolAddressAr || ''}
//                   onChange={(e) => setCurrentGrade({ ...currentGrade, schoolAddressAr: e.target.value })}
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="schoolContactNumber">Tel :</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="schoolContactNumber"
//                   placeholder="Contact"
//                   value={currentGrade?.schoolContactNumber || ''}
//                   onChange={(e) => setCurrentGrade({ ...currentGrade, schoolContactNumber: e.target.value })}
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="webSiteURL">Site :</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="webSiteURL"
//                   placeholder="Site"
//                   value={currentGrade?.webSiteURL || ''}
//                   onChange={(e) => setCurrentGrade({ ...currentGrade, webSiteURL: e.target.value })}
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="communeId">Commune :</label>
//                 <select
//                   className="form-control"
//                   id="communeId"
//                   value={currentGrade?.communeId || ''}
//                   onChange={(e) => setCurrentGrade({ ...currentGrade, communeId: e.target.value })}
//                 >
//                   <option value="">Sélectionner une commune</option>
//                   {Array.isArray(communes) && communes.map((commune) => (
//                     <option key={commune.id} value={commune.id}>
//                       {commune.name}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               <div className="form-group mt-2">
//                 <label htmlFor="isValide">Valide :</label>
//                 <input
//                   type="checkbox"
//                   className="form-check-input ms-2"
//                   id="isValide"
//                   checked={currentGrade?.isValide || false}
//                   onChange={(e) => setCurrentGrade({ ...currentGrade, isValide: e.target.checked })}
//                 />
//               </div>
//             </div>
//           )}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setShowModal(false)}>
//             Fermer
//           </Button>
//           {modalMode === 'delete' ? (
//             <Button variant="danger" onClick={handleDelete}>
//               Supprimer
//             </Button>
//           ) : (
//             <Button variant="primary" onClick={handleCreateOrUpdate}>
//               {modalMode === 'create' ? 'Ajouter' : 'Modifier'}
//             </Button>
//           )}
//         </Modal.Footer>
//       </Modal>

//     </div>
//   );
// };

// export default Schools;


// import React, { useState, useEffect } from 'react';
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
// import axiosInstance from '../utils/axiosInstance';

// const Schools = () => {
//   const [schools, setSchools] = useState([]);
//   const [communes, setCommunes] = useState([]);
//   const [filteredSchools, setFilteredSchools] = useState([]);
//   // const [currentGrade, setCurrentGrade] = useState(null);
//     const [currentGrade, setCurrentGrade] = useState({
//     schoolNameFr: '',
//     schoolNameAr: '',
//     schoolAddressFr: '',
//     schoolAddressAr: '',
//     schoolContactNumber: '',
//     webSiteURL: '',
//     communeId: '',
//     isValide: false,
//     createAccount: false,
//     password: '',
//     schoolEmail: '',
//     isAccountCreated : ''
//   });
//   const [showModal, setShowModal] = useState(false);
//   const [modalMode, setModalMode] = useState(''); // 'create' or 'update' or 'delete'
//   const [createAccount, setCreateAccount] = useState(false);
//   const [filter, setFilter] = useState({
//     schoolNameFr: '',
//     schoolNameAr: '',
//     schoolAddressFr: '',
//     schoolAddressAr: '',
//     schoolContactNumber: '',
//     communeId: '',
//     isValide: ''
//   });
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 10;

//   useEffect(() => {
//     fetchSchools();
//     fetchCommunes();
//   }, []);

//   // Fetch Communes
//   const fetchCommunes = async () => {
//     try {
//       const response = await axiosInstance.get('/communes');
//       const data = response.data.data; // Access the data array in the response
//       setCommunes(data);
//     } catch (error) {
//       console.error('An error occurred while fetching communes:', error);
//     }
//   };

//   // Fetch Schools
//   const fetchSchools = async () => {
//     try {
//       const response = await axiosInstance.get('/schoolsdata');
//       const data = response.data.data; // Access the data array in the response
//       setSchools(data);
//       setFilteredSchools(data);
//     } catch (error) {
//       setSchools([]);
//       setFilteredSchools([]);
//     }
//   };

//   const handleFilterChange = (e) => {
//     const { name, value } = e.target;
//     setFilter({
//       ...filter,
//       [name]: value
//     });
//   };

//   const handleFilterSubmit = (e) => {
//     e.preventDefault();
//     const filtered = schools.filter(school => {
//       const isNameFrMatch = filter.schoolNameFr ? school.schoolNameFr.toLowerCase().includes(filter.schoolNameFr.toLowerCase()) : true;
//       const isNameArMatch = filter.schoolNameAr ? school.schoolNameAr.includes(filter.schoolNameAr) : true;
//       const isAddressFrMatch = filter.schoolAddressFr ? school.schoolAddressFr.includes(filter.schoolAddressFr) : true;
//       const isAddressArMatch = filter.schoolAddressAr ? school.schoolAddressAr.includes(filter.schoolAddressAr) : true;
//       const isContactNumberMatch = filter.schoolContactNumber ? school.schoolContactNumber.includes(filter.schoolContactNumber) : true;
//       const isCommuneMatch = filter.communeId ? school.communeId === parseInt(filter.communeId) : true;
//       const isValideMatch = filter.isValide !== '' ? school.isValide.toString() === filter.isValide : true;

//       return isNameFrMatch && isNameArMatch && isAddressFrMatch && isAddressArMatch && isContactNumberMatch && isCommuneMatch && isValideMatch;
//     });

//     setFilteredSchools(filtered);
//     setCurrentPage(1); // Reset to first page after filtering
//   };

//   const getPaginatedData = () => {
//     const startIndex = (currentPage - 1) * itemsPerPage;
//     const endIndex = startIndex + itemsPerPage;
//     return filteredSchools.slice(startIndex, endIndex);
//   };

//   const totalPages = Math.ceil(filteredSchools.length / itemsPerPage);

//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   const renderPagination = () => {
//     const pages = [];
//     const start = Math.max(currentPage - 2, 1);
//     const end = Math.min(start + 4, totalPages);

//     for (let i = start; i <= end; i++) {
//       pages.push(
//         <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
//           <button className="page-link" onClick={() => handlePageChange(i)}>
//             {i}
//           </button>
//         </li>
//       );
//     }

//     return (
//       <div className="">
//         <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
//           <nav aria-label="Page navigation">
//             <ul className="pagination justify-content-end">
//               {/* First Button */}
//               <li className={`page-item ${currentPage > 1 ? '' : 'disabled'}`} id="DataTables_Table_0_first">
//                 <button className="page-link" onClick={() => handlePageChange(1)} disabled={currentPage <= 1}>
//                   <i className="tf-icon bx bx-chevrons-left"></i> First
//                 </button>
//               </li>

//               {/* Previous Button */}
//               <li className={`page-item ${currentPage > 1 ? '' : 'disabled'}`} id="DataTables_Table_0_previous">
//                 <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage <= 1}>
//                   <i className="tf-icon bx bx-chevron-left"></i> Previous
//                 </button>
//               </li>

//               {/* Page Numbers */}
//               {pages}

//               {/* Next Button */}
//               <li className={`page-item ${currentPage < totalPages ? '' : 'disabled'}`} id="DataTables_Table_0_next">
//                 <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage >= totalPages}>
//                   Next <i className="tf-icon bx bx-chevron-right"></i>
//                 </button>
//               </li>

//               {/* Last Button */}
//               <li className={`page-item ${currentPage < totalPages ? '' : 'disabled'}`} id="DataTables_Table_0_last">
//                 <button className="page-link" onClick={() => handlePageChange(totalPages)} disabled={currentPage >= totalPages}>
//                   Last <i className="tf-icon bx bx-chevrons-right"></i>
//                 </button>
//               </li>
//             </ul>
//           </nav>
//         </div>
//       </div>
//     );
//   };

//   const toggleModal = (grade = null, mode = '') => {
//     setCurrentGrade(grade);
//     setModalMode(mode);
//     setShowModal(!showModal);
//   };

//   const handleCreateOrUpdate = async () => {
//     const url = modalMode === 'create'
//       ? '/schoolsdata'
//       : `/schoolsdata/${currentGrade.id}`;
//     const method = modalMode === 'create' ? 'POST' : 'PUT';

//     try {
//       const data = { ...currentGrade };

//       await axiosInstance({
//         method,
//         url,
//         data,
//       });
//       fetchSchools(); // Refresh the school list
//     } catch (error) {
//       console.error(`An error occurred while ${modalMode === 'create' ? 'creating' : 'updating'} the school:`, error);
//     }
//     setShowModal(false);
//   };

//   const handleDelete = async () => {
//     try {
//       await axiosInstance.delete(`/schoolsdata/${currentGrade.id}`);
//       fetchSchools(); // Refresh the school list
//     } catch (error) {
//       console.error('An error occurred while deleting the school:', error);
//     }
//     setShowModal(false);
//   };

//   const handleResetFilter = () => {
//     setFilter({
//       schoolNameFr: '',
//       schoolNameAr: '',
//       schoolAddressFr: '',
//       schoolAddressAr: '',
//       schoolContactNumber: '',
//       communeId: '',
//       isValide: ''
//     });
//     setFilteredSchools(schools);
//     setCurrentPage(1); // Reset to first page after resetting
//   };

//   return (
//     <div className="layout-wrapper layout-content-navbar">
//       <div className="layout-container">
//         <div className="layout-page">
//           <div className="container-xxl flex-grow-1 container-p-y">
//             <h4 className="py-3 mb-4">
//               <span className="text-muted fw-light">Schools /</span> Liste
//             </h4>
//             <div className="card">
//               <div className="card-datatable table-responsive">
//                 <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
//                   <div className="row mx-2 mb-2 mt-4">
//                     <div className="col-md-12 mt-2">
//                       <form onSubmit={handleFilterSubmit}>
//                         <div className="row g-3">
//                           <div className="d-flex align-items-end justify-content-end">
//                             <button
//                               type="button"
//                               className="btn btn-primary me-3"
//                               onClick={() => toggleModal(null, 'create')}
//                             >
//                               <i className="bx bx-plus"></i> Add new
//                             </button>
//                           </div>
//                           <div className="col-md-2">
//                             <label htmlFor="isValide" className="form-label">Valide</label>
//                             <select
//                               id="isValide"
//                               name="isValide"
//                               className="form-control"
//                               value={filter.isValide}
//                               onChange={handleFilterChange}
//                             >
//                               <option value="">All</option>
//                               <option value="1">Valid</option>
//                               <option value="0">Invalid</option>
//                             </select>
//                           </div>
//                           <div className="col-md-2">
//                             <label htmlFor="schoolNameFr" className="form-label">Name (FR)</label>
//                             <input
//                               type="search"
//                               id="schoolNameFr"
//                               name="schoolNameFr"
//                               className="form-control"
//                               placeholder="Nom (FR)"
//                               value={filter.schoolNameFr}
//                               onChange={handleFilterChange}
//                             />
//                           </div>
//                           <div className="col-md-2">
//                             <label htmlFor="schoolNameAr" className="form-label">Name (AR)</label>
//                             <input
//                               type="search"
//                               id="schoolNameAr"
//                               name="schoolNameAr"
//                               className="form-control"
//                               placeholder="Nom (AR)"
//                               value={filter.schoolNameAr}
//                               onChange={handleFilterChange}
//                             />
//                           </div>
//                           <div className="col-md-2">
//                             <label htmlFor="schoolAddressFr" className="form-label">Addresse (FR)</label>
//                             <input
//                               type="search"
//                               id="schoolAddressFr"
//                               name="schoolAddressFr"
//                               className="form-control"
//                               placeholder="Adresse (FR)"
//                               value={filter.schoolAddressFr}
//                               onChange={handleFilterChange}
//                             />
//                           </div>
//                           <div className="col-md-2">
//                             <label htmlFor="schoolAddressAr" className="form-label">Addresse (AR)</label>
//                             <input
//                               type="search"
//                               id="schoolAddressAr"
//                               name="schoolAddressAr"
//                               className="form-control"
//                               placeholder="Adresse (AR)"
//                               value={filter.schoolAddressAr}
//                               onChange={handleFilterChange}
//                             />
//                           </div>
//                           <div className="col-md-2">
//                             <label htmlFor="schoolContactNumber" className="form-label">Contact</label>
//                             <input
//                               type="search"
//                               id="schoolContactNumber"
//                               name="schoolContactNumber"
//                               className="form-control"
//                               placeholder="Contact"
//                               value={filter.schoolContactNumber}
//                               onChange={handleFilterChange}
//                             />
//                           </div>
//                           <div className="col-md-2">
//                             <label htmlFor="communeId" className="form-label">Commune</label>
//                             <select
//                               id="communeId"
//                               name="communeId"
//                               className="form-control"
//                               value={filter.communeId}
//                               onChange={handleFilterChange}
//                             >
//                               <option value="">Select Commune</option>
//                               {communes.map((commune) => (
//                                 <option key={commune.id} value={commune.id}>
//                                   {commune.name}
//                                 </option>
//                               ))}
//                             </select>
//                           </div>

//                           <div className="d-flex align-items-end justify-content-end">
//                             <button type="submit" className="btn btn-outline-secondary me-3">
//                               <i className="bx bx-search"></i> Search
//                             </button>
//                             <button type="button" className="btn btn-outline-danger" onClick={handleResetFilter}>
//                               <i className="bx bx-reset"></i> Reset Filter
//                             </button>
//                           </div>
//                         </div>
//                       </form>
//                     </div>
//                   </div>
//                   <table
//                     className="datatables-grades table border-top dataTable no-footer dtr-column"
//                     id="DataTables_Table_0"
//                     style={{ width: '100%' }}
//                   >
//                     <thead>
//                       <tr>
//                         <th>Name Fr</th>
//                         <th>Name Ar</th>
//                         <th>Addresse Fr</th>
//                         <th>Addresse Ar</th>
//                         <th>Commune</th>
//                         <th>Numéro de Contact</th>
//                         <th>Site</th>
//                         <th>Map</th>
//                         <th>Actions</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {Array.isArray(filteredSchools) && getPaginatedData().length > 0 ? (
//                         getPaginatedData().map((school) => (
//                           <tr key={school.id}>
//                             <td>{school.schoolNameFr}</td>
//                             <td>{school.schoolNameAr}</td>
//                             <td>{school.schoolAddressFr}</td>
//                             <td>{school.schoolAddressAr}</td>
//                             <td>{school.commune ? school.commune.name : 'N/A'}</td>
//                             <td>{school.schoolContactNumber}</td>
//                             <td><a href={school.webSiteURL} target="_blank" rel="noopener noreferrer">Site</a></td>
//                             <td><a href={school.mapURL} target="_blank" rel="noopener noreferrer">Map</a></td>
//                             <td className="text-start">
//                               <div className="d-inline-block text-nowrap">
//                                 <button className="btn btn-sm btn-icon" onClick={() => toggleModal(school, 'update')} title="Modifier">
//                                   <i className="bx bx-edit"></i>
//                                 </button>
//                                 <button className="btn btn-sm btn-icon" onClick={() => toggleModal(school, 'delete')} title="Supprimer">
//                                   <i className="bx bx-trash"></i>
//                                 </button>
//                               </div>
//                             </td>
//                           </tr>
//                         ))
//                       ) : (
//                         <tr>
//                           <td colSpan="6">No schools found.</td>
//                         </tr>
//                       )}
//                     </tbody>
//                   </table>
//                   <div className="row mx-2 mt-4 justify-content-end">
//                     <div className="col-sm-12 col-md-6 text-right">
//                       <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
//                         Showing {((currentPage - 1) * itemsPerPage) + 1} to {Math.min(currentPage * itemsPerPage, filteredSchools.length)} of {filteredSchools.length} entries
//                       </div>
//                     </div>
//                     <div className="col-sm-12 col-md-6">
//                       <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
//                         {renderPagination()}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* Modal */}
//       <Modal show={showModal} onHide={() => setShowModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>
//             {modalMode === 'create' ? 'Ajouter School' : modalMode === 'update' ? 'Modifier School' : 'Supprimer School'}
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {modalMode === 'delete' ? (
//             <p>Êtes-vous sûr de vouloir supprimer cette school?</p>
//           ) : (
//             <div>
//               <div className="form-group">
//                 <label htmlFor="schoolNameFr">Nom (FR) :</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="schoolNameFr"
//                   placeholder="Nom (FR)"
//                   value={currentGrade?.schoolNameFr || ''}
//                   onChange={(e) => setCurrentGrade({ ...currentGrade, schoolNameFr: e.target.value })}
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="schoolNameAr">Nom (AR) :</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="schoolNameAr"
//                   placeholder="Nom (AR)"
//                   value={currentGrade?.schoolNameAr || ''}
//                   onChange={(e) => setCurrentGrade({ ...currentGrade, schoolNameAr: e.target.value })}
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="schoolAddressFr">Adresse (FR) :</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="schoolAddressFr"
//                   placeholder="Adresse (FR)"
//                   value={currentGrade?.schoolAddressFr || ''}
//                   onChange={(e) => setCurrentGrade({ ...currentGrade, schoolAddressFr: e.target.value })}
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="schoolAddressAr">Adresse (AR) :</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="schoolAddressAr"
//                   placeholder="Adresse (AR)"
//                   value={currentGrade?.schoolAddressAr || ''}
//                   onChange={(e) => setCurrentGrade({ ...currentGrade, schoolAddressAr: e.target.value })}
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="schoolContactNumber">Tel :</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="schoolContactNumber"
//                   placeholder="Contact"
//                   value={currentGrade?.schoolContactNumber || ''}
//                   onChange={(e) => setCurrentGrade({ ...currentGrade, schoolContactNumber: e.target.value })}
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="schoolEmail">Email :</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="schoolEmail"
//                   placeholder="Email"
//                   value={currentGrade?.schoolEmail || ''}
//                   onChange={(e) => setCurrentGrade({ ...currentGrade, schoolEmail: e.target.value })}
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="webSiteURL">Site :</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   id="webSiteURL"
//                   placeholder="Site"
//                   value={currentGrade?.webSiteURL || ''}
//                   onChange={(e) => setCurrentGrade({ ...currentGrade, webSiteURL: e.target.value })}
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="communeId">Commune :</label>
//                 <select
//                   className="form-control"
//                   id="communeId"
//                   value={currentGrade?.communeId || ''}
//                   onChange={(e) => setCurrentGrade({ ...currentGrade, communeId: e.target.value })}
//                 >
//                   <option value="">Sélectionner une commune</option>
//                   {Array.isArray(communes) && communes.map((commune) => (
//                     <option key={commune.id} value={commune.id}>
//                       {commune.name}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               <div className="form-group mt-2">
//                 <label htmlFor="isValide">Valide :</label>
//                 <input
//                   type="checkbox"
//                   className="form-check-input ms-2"
//                   id="isValide"
//                   checked={currentGrade?.isValide || false}
//                   onChange={(e) => setCurrentGrade({ ...currentGrade, isValide: e.target.checked })}
//                 />
//               </div>
//               {currentGrade?.isAccountCreated ? (
//                 <div className="alert alert-success mt-2" role="alert">
//                   Compte créé
//                 </div>
//               ) : (
//                 <div className="form-group mt-2">
//                   <label htmlFor="createAccount">Créer un compte pour cette école :</label>
//                   <input
//                     type="checkbox"
//                     className="form-check-input ms-2"
//                     id="createAccount"
//                     checked={currentGrade?.createAccount || false}
//                     onChange={(e) => {
//                       const schoolContactNumber = currentGrade?.schoolContactNumber || '';
//                       setCurrentGrade({
//                         ...currentGrade,
//                         createAccount: e.target.checked,
//                         password: e.target.checked ? schoolContactNumber : '', // Set password only if createAccount is checked
//                       });
//                     }}
//                   />
//                 </div>
//               )}

//               {currentGrade?.createAccount && (
//                 <div className="form-group mt-2">
//                   <label htmlFor="password">Mot de passe :</label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="password"
//                     placeholder="Mot de passe"
//                     value={currentGrade?.password || ''} // Use currentGrade.password
//                     onChange={(e) => setCurrentGrade({ ...currentGrade, password: e.target.value })}
//                   />
//                 </div>
//               )}

//             </div>
//           )}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setShowModal(false)}>
//             Fermer
//           </Button>
//           {modalMode === 'delete' ? (
//             <Button variant="danger" onClick={handleDelete}>
//               Supprimer
//             </Button>
//           ) : (
//             <Button variant="primary" onClick={handleCreateOrUpdate}>
//               {modalMode === 'create' ? 'Ajouter' : 'Modifier'}
//             </Button>
//           )}
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// };

// export default Schools;


import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axiosInstance from '../utils/axiosInstance';

const Schools = () => {
  const [schools, setSchools] = useState([]);
  const [communes, setCommunes] = useState([]);
  const [filteredSchools, setFilteredSchools] = useState([]);
  const [currentGrade, setCurrentGrade] = useState({
    schoolNameFr: '',
    schoolNameAr: '',
    schoolAddressFr: '',
    schoolAddressAr: '',
    schoolContactNumber: '',
    webSiteURL: '',
    communeId: '',
    isValide: false,
    createAccount: false,
    password: '',
    schoolEmail: '',
    isAccountCreated: ''
  });
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState(''); // 'create' or 'update' or 'delete'
  const [filter, setFilter] = useState({
    schoolNameFr: '',
    schoolNameAr: '',
    schoolAddressFr: '',
    schoolAddressAr: '',
    schoolContactNumber: '',
    communeId: '',
    isValide: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchSchools();
    fetchCommunes();
  }, []);

  // Fetch Communes
  const fetchCommunes = async () => {
    try {
      const response = await axiosInstance.get('/communes');
      const data = response.data.data; // Access the data array in the response
      setCommunes(data);
    } catch (error) {
      console.error('Une erreur est survenue lors de la récupération des communes:', error);
    }
  };

  // Fetch Schools
  const fetchSchools = async () => {
    try {
      const response = await axiosInstance.get('/schoolsdata');
      const data = response.data.data; // Access the data array in the response
      setSchools(data);
      setFilteredSchools(data);
    } catch (error) {
      setSchools([]);
      setFilteredSchools([]);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter({
      ...filter,
      [name]: value
    });
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    const filtered = schools.filter(school => {
      const isNameFrMatch = filter.schoolNameFr ? school.schoolNameFr.toLowerCase().includes(filter.schoolNameFr.toLowerCase()) : true;
      const isNameArMatch = filter.schoolNameAr ? school.schoolNameAr.includes(filter.schoolNameAr) : true;
      const isAddressFrMatch = filter.schoolAddressFr ? school.schoolAddressFr.includes(filter.schoolAddressFr) : true;
      const isAddressArMatch = filter.schoolAddressAr ? school.schoolAddressAr.includes(filter.schoolAddressAr) : true;
      const isContactNumberMatch = filter.schoolContactNumber ? school.schoolContactNumber.includes(filter.schoolContactNumber) : true;
      const isCommuneMatch = filter.communeId ? school.communeId === parseInt(filter.communeId) : true;
      const isValideMatch = filter.isValide !== '' ? school.isValide.toString() === filter.isValide : true;

      return isNameFrMatch && isNameArMatch && isAddressFrMatch && isAddressArMatch && isContactNumberMatch && isCommuneMatch && isValideMatch;
    });

    setFilteredSchools(filtered);
    setCurrentPage(1); // Reset to first page after filtering
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredSchools.slice(startIndex, endIndex);
  };

  const totalPages = Math.ceil(filteredSchools.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    const pages = [];
    const start = Math.max(currentPage - 2, 1);
    const end = Math.min(start + 4, totalPages);

    for (let i = start; i <= end; i++) {
      pages.push(
        <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }

    return (
      <div className="">
        <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
          <nav aria-label="Page navigation">
            <ul className="pagination justify-content-end">
              {/* First Button */}
              <li className={`page-item ${currentPage > 1 ? '' : 'disabled'}`} id="DataTables_Table_0_first">
                <button className="page-link" onClick={() => handlePageChange(1)} disabled={currentPage <= 1}>
                  <i className="tf-icon bx bx-chevrons-left"></i> Premier
                </button>
              </li>

              {/* Previous Button */}
              <li className={`page-item ${currentPage > 1 ? '' : 'disabled'}`} id="DataTables_Table_0_previous">
                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage <= 1}>
                  <i className="tf-icon bx bx-chevron-left"></i> Précédent
                </button>
              </li>

              {/* Page Numbers */}
              {pages}

              {/* Next Button */}
              <li className={`page-item ${currentPage < totalPages ? '' : 'disabled'}`} id="DataTables_Table_0_next">
                <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage >= totalPages}>
                  Suivant <i className="tf-icon bx bx-chevron-right"></i>
                </button>
              </li>

              {/* Last Button */}
              <li className={`page-item ${currentPage < totalPages ? '' : 'disabled'}`} id="DataTables_Table_0_last">
                <button className="page-link" onClick={() => handlePageChange(totalPages)} disabled={currentPage >= totalPages}>
                  Dernier <i className="tf-icon bx bx-chevrons-right"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  };

  const toggleModal = (grade = null, mode = '') => {
    setCurrentGrade(grade);
    setModalMode(mode);
    setShowModal(!showModal);
  };

  const handleCreateOrUpdate = async () => {
    const url = modalMode === 'create'
      ? '/schoolsdata'
      : `/schoolsdata/${currentGrade.id}`;
    const method = modalMode === 'create' ? 'POST' : 'PUT';

    try {
      const data = { ...currentGrade };

      await axiosInstance({
        method,
        url,
        data,
      });
      fetchSchools(); // Refresh the school list
    } catch (error) {
      console.error(`Une erreur est survenue lors de la ${modalMode === 'create' ? 'création' : 'mise à jour'} de l'école:`, error);
    }
    setShowModal(false);
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.delete(`/schoolsdata/${currentGrade.id}`);
      fetchSchools(); // Refresh the school list
    } catch (error) {
      console.error('Une erreur est survenue lors de la suppression de l\'école:', error);
    }
    setShowModal(false);
  };

  const handleResetFilter = () => {
    setFilter({
      schoolNameFr: '',
      schoolNameAr: '',
      schoolAddressFr: '',
      schoolAddressAr: '',
      schoolContactNumber: '',
      communeId: '',
      isValide: ''
    });
    setFilteredSchools(schools);
    setCurrentPage(1); // Reset to first page after resetting
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <div className="layout-page">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="py-3 mb-4">
              <span className="text-muted fw-light">Écoles /</span> Liste
            </h4>
            <div className="card">
              <div className="card-datatable table-responsive">
                <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                  <div className="row mx-2 mb-2 mt-4">
                    <div className="col-md-12 mt-2">
                      <form onSubmit={handleFilterSubmit}>
                        <div className="row g-3">
                          <div className="d-flex align-items-end justify-content-end">
                            <button
                              type="button"
                              className="btn btn-primary me-3"
                              onClick={() => toggleModal(null, 'create')}
                            >
                              <i className="bx bx-plus"></i> Ajouter nouveau
                            </button>
                          </div>
                          <div className="col-md-2">
                            <label htmlFor="isValide" className="form-label">Valide</label>
                            <select
                              id="isValide"
                              name="isValide"
                              className="form-control"
                              value={filter.isValide}
                              onChange={handleFilterChange}
                            >
                              <option value="">Tous</option>
                              <option value="1">Valide</option>
                              <option value="0">Invalide</option>
                            </select>
                          </div>
                          <div className="col-md-2">
                            <label htmlFor="schoolNameFr" className="form-label">Nom (FR)</label>
                            <input
                              type="search"
                              id="schoolNameFr"
                              name="schoolNameFr"
                              className="form-control"
                              placeholder="Nom (FR)"
                              value={filter.schoolNameFr}
                              onChange={handleFilterChange}
                            />
                          </div>
                          <div className="col-md-2">
                            <label htmlFor="schoolNameAr" className="form-label">Nom (AR)</label>
                            <input
                              type="search"
                              id="schoolNameAr"
                              name="schoolNameAr"
                              className="form-control"
                              placeholder="Nom (AR)"
                              value={filter.schoolNameAr}
                              onChange={handleFilterChange}
                            />
                          </div>
                          <div className="col-md-2">
                            <label htmlFor="schoolAddressFr" className="form-label">Adresse (FR)</label>
                            <input
                              type="search"
                              id="schoolAddressFr"
                              name="schoolAddressFr"
                              className="form-control"
                              placeholder="Adresse (FR)"
                              value={filter.schoolAddressFr}
                              onChange={handleFilterChange}
                            />
                          </div>
                          <div className="col-md-2">
                            <label htmlFor="schoolAddressAr" className="form-label">Adresse (AR)</label>
                            <input
                              type="search"
                              id="schoolAddressAr"
                              name="schoolAddressAr"
                              className="form-control"
                              placeholder="Adresse (AR)"
                              value={filter.schoolAddressAr}
                              onChange={handleFilterChange}
                            />
                          </div>
                          <div className="col-md-2">
                            <label htmlFor="schoolContactNumber" className="form-label">Contact</label>
                            <input
                              type="search"
                              id="schoolContactNumber"
                              name="schoolContactNumber"
                              className="form-control"
                              placeholder="Contact"
                              value={filter.schoolContactNumber}
                              onChange={handleFilterChange}
                            />
                          </div>
                          <div className="col-md-2">
                            <label htmlFor="communeId" className="form-label">Commune</label>
                            <select
                              id="communeId"
                              name="communeId"
                              className="form-control"
                              value={filter.communeId}
                              onChange={handleFilterChange}
                            >
                              <option value="">Sélectionner une commune</option>
                              {communes.map((commune) => (
                                <option key={commune.id} value={commune.id}>
                                  {commune.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="d-flex align-items-end justify-content-end">
                            <button type="submit" className="btn btn-outline-secondary me-3">
                              <i className="bx bx-search"></i> Rechercher
                            </button>
                            <button type="button" className="btn btn-outline-danger" onClick={handleResetFilter}>
                              <i className="bx bx-reset"></i> Réinitialiser le filtre
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="table-responsive">
                  <table
                    className="datatables-grades table border-top dataTable no-footer dtr-column"
                    id="DataTables_Table_0"
                    style={{ width: '100%' }}
                  >
                    <thead>
                      <tr>
                        <th>Nom Fr</th>
                        <th>Nom Ar</th>
                        <th>Adresse Fr</th>
                        <th>Adresse Ar</th>
                        <th>Commune</th>
                        <th>Numéro de Contact</th>
                        <th>Site</th>
                        <th>Carte</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(filteredSchools) && getPaginatedData().length > 0 ? (
                        getPaginatedData().map((school) => (
                          <tr key={school.id}>
                            <td>{school.schoolNameFr}</td>
                            <td>{school.schoolNameAr}</td>
                            <td>{school.schoolAddressFr}</td>
                            <td>{school.schoolAddressAr}</td>
                            <td>{school.commune ? school.commune.name : 'N/A'}</td>
                            <td>{school.schoolContactNumber}</td>
                            <td><a href={school.webSiteURL} target="_blank" rel="noopener noreferrer">Site</a></td>
                            <td><a href={school.mapURL} target="_blank" rel="noopener noreferrer">Carte</a></td>
                            <td className="text-start">
                              <div className="d-inline-block text-nowrap">
                                <button className="btn btn-sm btn-icon" onClick={() => toggleModal(school, 'update')} title="Modifier">
                                  <i className="bx bx-edit"></i>
                                </button>
                                <button className="btn btn-sm btn-icon" onClick={() => toggleModal(school, 'delete')} title="Supprimer">
                                  <i className="bx bx-trash"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="9">Aucune école trouvée.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  </div>
                  <div className="row mx-2 mt-4 justify-content-end">
                    <div className="col-sm-12 col-md-6 text-right">
                      <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                        Affichage de {((currentPage - 1) * itemsPerPage) + 1} à {Math.min(currentPage * itemsPerPage, filteredSchools.length)} sur {filteredSchools.length} entrées
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                        {renderPagination()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalMode === 'create' ? 'Ajouter École' : modalMode === 'update' ? 'Modifier École' : 'Supprimer École'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalMode === 'delete' ? (
            <p>Êtes-vous sûr de vouloir supprimer cette école?</p>
          ) : (
            <div>
              <div className="form-group">
                <label htmlFor="schoolNameFr">Nom (FR) :</label>
                <input
                  type="text"
                  className="form-control"
                  id="schoolNameFr"
                  placeholder="Nom (FR)"
                  value={currentGrade?.schoolNameFr || ''}
                  onChange={(e) => setCurrentGrade({ ...currentGrade, schoolNameFr: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="schoolNameAr">Nom (AR) :</label>
                <input
                  type="text"
                  className="form-control"
                  id="schoolNameAr"
                  placeholder="Nom (AR)"
                  value={currentGrade?.schoolNameAr || ''}
                  onChange={(e) => setCurrentGrade({ ...currentGrade, schoolNameAr: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="schoolAddressFr">Adresse (FR) :</label>
                <input
                  type="text"
                  className="form-control"
                  id="schoolAddressFr"
                  placeholder="Adresse (FR)"
                  value={currentGrade?.schoolAddressFr || ''}
                  onChange={(e) => setCurrentGrade({ ...currentGrade, schoolAddressFr: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="schoolAddressAr">Adresse (AR) :</label>
                <input
                  type="text"
                  className="form-control"
                  id="schoolAddressAr"
                  placeholder="Adresse (AR)"
                  value={currentGrade?.schoolAddressAr || ''}
                  onChange={(e) => setCurrentGrade({ ...currentGrade, schoolAddressAr: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="schoolContactNumber">Téléphone :</label>
                <input
                  type="text"
                  className="form-control"
                  id="schoolContactNumber"
                  placeholder="Contact"
                  value={currentGrade?.schoolContactNumber || ''}
                  onChange={(e) => setCurrentGrade({ ...currentGrade, schoolContactNumber: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="schoolEmail">Email :</label>
                <input
                  type="text"
                  className="form-control"
                  id="schoolEmail"
                  placeholder="Email"
                  value={currentGrade?.schoolEmail || ''}
                  onChange={(e) => setCurrentGrade({ ...currentGrade, schoolEmail: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="webSiteURL">Site :</label>
                <input
                  type="text"
                  className="form-control"
                  id="webSiteURL"
                  placeholder="Site"
                  value={currentGrade?.webSiteURL || ''}
                  onChange={(e) => setCurrentGrade({ ...currentGrade, webSiteURL: e.target.value })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="communeId">Commune :</label>
                <select
                  className="form-control"
                  id="communeId"
                  value={currentGrade?.communeId || ''}
                  onChange={(e) => setCurrentGrade({ ...currentGrade, communeId: e.target.value })}
                >
                  <option value="">Sélectionner une commune</option>
                  {Array.isArray(communes) && communes.map((commune) => (
                    <option key={commune.id} value={commune.id}>
                      {commune.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group mt-2">
                <label htmlFor="isValide">Valide :</label>
                <input
                  type="checkbox"
                  className="form-check-input ms-2"
                  id="isValide"
                  checked={currentGrade?.isValide || false}
                  onChange={(e) => setCurrentGrade({ ...currentGrade, isValide: e.target.checked })}
                />
              </div>
              {currentGrade?.isAccountCreated ? (
                <div className="alert alert-success mt-2" role="alert">
                  Compte créé
                </div>
              ) : (
                <div className="form-group mt-2">
                  <label htmlFor="createAccount">Créer un compte pour cette école :</label>
                  <input
                    type="checkbox"
                    className="form-check-input ms-2"
                    id="createAccount"
                    checked={currentGrade?.createAccount || false}
                    onChange={(e) => {
                      const schoolContactNumber = currentGrade?.schoolContactNumber || '';
                      setCurrentGrade({
                        ...currentGrade,
                        createAccount: e.target.checked,
                        password: e.target.checked ? schoolContactNumber : '', // Set password only if createAccount is checked
                      });
                    }}
                  />
                </div>
              )}

              {currentGrade?.createAccount && (
                <div className="form-group mt-2">
                  <label htmlFor="password">Mot de passe :</label>
                  <input
                    type="text"
                    className="form-control"
                    id="password"
                    placeholder="Mot de passe"
                    value={currentGrade?.password || ''} // Use currentGrade.password
                    onChange={(e) => setCurrentGrade({ ...currentGrade, password: e.target.value })}
                  />
                </div>
              )}

            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Fermer
          </Button>
          {modalMode === 'delete' ? (
            <Button variant="danger" onClick={handleDelete}>
              Supprimer
            </Button>
          ) : (
            <Button variant="primary" onClick={handleCreateOrUpdate}>
              {modalMode === 'create' ? 'Ajouter' : 'Modifier'}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Schools;

