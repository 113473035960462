import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import myImage from '../images/1.png';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const Navbar = ({ toggleSidebar }) => {
  const navigate = useNavigate();
  const { user, logout } = useContext(AuthContext); // Consume AuthContext
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };
  const handleToggleSidebar = () => {
    toggleSidebar(); // Call the passed toggleSidebar function
  };
  return (
    <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
        <a className="nav-item nav-link px-0 me-xl-4" onClick={handleToggleSidebar}>
          <i className="bx bx-menu bx-sm"></i>
        </a>
      </div>
      <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
        <div className="navbar-nav align-items-center">
          <div className="nav-item navbar-search-wrapper mb-0">
            <a className="nav-item nav-link search-toggler px-0">
              <i className="bx bx-search bx-sm"></i>
              <span className="d-none d-md-inline-block text-muted">Search (Ctrl+/)</span>
            </a>
          </div>
        </div>
        <ul className="navbar-nav flex-row align-items-center ms-auto">
          <li className="nav-item dropdown-style-switcher dropdown me-2 me-xl-0">
            <a className="nav-link dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
              <i className="bx bx-sm"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-end dropdown-styles">
              <li>
                <a className="dropdown-item" data-theme="light">
                  <span className="align-middle"><i className="bx bx-sun me-2"></i>Light</span>
                </a>
              </li>
              <li>
                <a className="dropdown-item" data-theme="dark">
                  <span className="align-middle"><i className="bx bx-moon me-2"></i>Dark</span>
                </a>
              </li>
              <li>
                <a className="dropdown-item" data-theme="system">
                  <span className="align-middle"><i className="bx bx-desktop me-2"></i>System</span>
                </a>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-1">
            <a className="nav-link dropdown-toggle hide-arrow" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
              <i className="bx bx-bell bx-sm"></i>
              <span className="badge bg-danger rounded-pill badge-notifications">5</span>
            </a>
            <ul className="dropdown-menu dropdown-menu-end py-0">
              <li className="dropdown-menu-header border-bottom">
                <div className="dropdown-header d-flex align-items-center py-3">
                  <h5 className="text-body mb-0 me-auto">Notification</h5>
                  <a href="javascript:void(0)" className="dropdown-notifications-all text-body" data-bs-toggle="tooltip" data-bs-placement="top" title="Mark all as read">
                    <i className="bx fs-4 bx-envelope-open"></i>
                  </a>
                </div>
              </li>
              <li className="dropdown-notifications-list scrollable-container">
                <ul className="list-group list-group-flush">
                  {/* List items */}
                </ul>
              </li>
              <li className="dropdown-menu-footer border-top p-3">
                <button className="btn btn-primary text-uppercase w-100">View all notifications</button>
              </li>
            </ul>
          </li>
          <li className="nav-item navbar-dropdown dropdown-user dropdown">
            <a className="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
              <div className="avatar avatar-online">
                <img src={myImage} className="w-px-40 h-auto rounded-circle" />
              </div>
            </a>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <Link className="dropdown-item" to="/profile/edit">
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar avatar-online">
                        <img src={myImage} alt="Avatar" className="w-px-40 h-auto rounded-circle" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <span className="fw-medium d-block">{user ? user.name : 'User Name'}</span>
                      <small className="text-muted">System admin</small>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <div className="dropdown-divider"></div>
              </li>
              <li>
                <Link className="dropdown-item" to="/profile/edit">
                  <i className="bx bx-user me-2"></i>
                  <span className="align-middle">Profile</span>
                </Link>
              </li>
              <li>
                <div className="dropdown-divider"></div>
              </li>
              <li>
                <button type="button" className="dropdown-item" onClick={handleLogout}>
                  <i className="bx bx-power-off me-2"></i>
                  <span className="align-middle">Se Déconnecter</span>
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;