import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom'; // Use Navigate instead of Redirect
import AuthContext from '../context/AuthContext';

const RoleBasedRoute = ({ roles, element }) => {
  const { user, isAuthenticated, loading } = useContext(AuthContext);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />; // Use Navigate instead of Redirect
  }

  // Check if the user has any of the required roles
  if (!roles.some(role => user.roles.includes(role))) {
    return <Navigate to="/" />; // Use Navigate instead of Redirect
  }

  return element;
};

export default RoleBasedRoute;
