

import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HarmoniaLogo from '../images/register.jpg';
import LoginImage from '../images/web_logo_dark.png';
import './Register.css';
import axiosInstance from '../utils/axiosInstance';
import AuthContext from '../context/AuthContext';
import { Link } from 'react-router-dom';

const Register = () => {
    const [formData, setFormData] = useState({
        storeName: '',
        addressFr: '',
        commune: '',
        storeContactNumber: '',
        password: '',
        name: '',
        email: '',
        mapURL: '',
        webSiteURL: ''
    });
    const [errors, setErrors] = useState({});
    const [communes, setCommunes] = useState([]);
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);

    useEffect(() => {
        const fetchCommunes = async () => {
            try {
                const response = await axiosInstance.get('select/communes');
                setCommunes(response.data.data);
            } catch (error) {
                console.error('Error fetching communes:', error);
            }
        };

        fetchCommunes();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        // Clear the error for the specific field
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!formData.storeName) {
            formErrors.storeName = 'Nom de la bibliothèque est requis';
            isValid = false;
        }

        if (!formData.password) {
            formErrors.password = 'Mot de passe est requis';
            isValid = false;
        }

        if (!formData.addressFr) {
            formErrors.addressFr = 'L\'adresse de la bibliothèque en français est requise';
            isValid = false;
        }

        if (!formData.commune) {
            formErrors.commune = 'Commune est requise';
            isValid = false;
        }

        if (!formData.storeContactNumber) {
            formErrors.storeContactNumber = 'Numéro de contact de la bibliothèque est requis';
            isValid = false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (formData.email && !emailRegex.test(formData.email)) {
            formErrors.email = 'Format d\'email invalide';
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        
        e.preventDefault();
        setErrors({});

        if (!validateForm()) {
           
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('password', formData.password);
        formDataToSend.append('storeName', formData.storeName);
        formDataToSend.append('addressFr', formData.addressFr);
        formDataToSend.append('communeId', parseInt(formData.commune, 10));
        formDataToSend.append('storeContactNumber', formData.storeContactNumber);

        try {
            const response = await axiosInstance.post('/store/create', formDataToSend, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = response.data;

            if (response.status === 200 && data.status === "success") {
                // navigate('/');
            } else {
                setErrors(data.errors || { general: 'Registration failed' });
            }
        } catch (error) {
            setErrors({ general: 'An error occurred. Please try again.' });
        }
    };

    return (
        <div className="authentication-wrapper2">
            <div
                className="background-section"
                style={{
                    backgroundImage: `url(${HarmoniaLogo})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            ></div>

            <div className="login-section">
                <div className="form-container">
                    <div className="app-brand mb-5 d-flex justify-content-center">
                        <a href="/" className="app-brand-link gap-2">
                            <span className="app-brand-logo demo mobile-logo">
                                <img
                                    src={LoginImage}
                                    className="img-fluid"
                                    alt="Image de connexion"
                                    width="200"
                                />
                            </span>
                        </a>
                    </div>

                    <h4 className="mb-2">L'aventure commence ici 🚀</h4>
                    <p className="mb-4">Simplifiez et amusez-vous avec la gestion de votre bibliothèque !</p>
                    <form className="mb-3" onSubmit={handleSubmit} noValidate>
                        <div className="mb-3">
                            <label htmlFor="storeContactNumber" className="form-label">
                                Numéro de contact de la bibliothèque
                            </label>
                            <input
                                type="text"
                                className={`form-control ${errors.storeContactNumber ? 'is-invalid' : ''}`}
                                id="storeContactNumber"
                                name="storeContactNumber"
                                value={formData.storeContactNumber}
                                onChange={handleChange}
                                placeholder="Entrez le numéro de contact de la bibliothèque"
                                
                            />
                            {errors.storeContactNumber && <div className="invalid-feedback">{errors.storeContactNumber}</div>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="storeName" className="form-label">
                                Nom de la bibliothèque
                            </label>
                            <input
                                type="text"
                                className={`form-control ${errors.storeName ? 'is-invalid' : ''}`}
                                id="storeName"
                                name="storeName"
                                value={formData.storeName}
                                onChange={handleChange}
                                placeholder="Entrez le nom de la bibliothèque"
                                
                            />
                            {errors.storeName && <div className="invalid-feedback">{errors.storeName}</div>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="addressFr" className="form-label">
                                Adresse de la bibliothèque
                            </label>
                            <input
                                type="text"
                                className={`form-control ${errors.addressFr ? 'is-invalid' : ''}`}
                                id="addressFr"
                                name="addressFr"
                                value={formData.addressFr}
                                onChange={handleChange}
                                placeholder="Entrez votre adresse"
                                
                            />
                            {errors.addressFr && <div className="invalid-feedback">{errors.addressFr}</div>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="commune" className="form-label">
                                Ville
                            </label>
                            <select
                                className={`form-select ${errors.commune ? 'is-invalid' : ''}`}
                                id="commune"
                                name="commune"
                                value={formData.commune}
                                onChange={handleChange}
                                
                            >
                                <option value="">Choisissez une ville</option>
                                {communes.map((commune) => (
                                    <option key={commune.id} value={commune.id}>
                                        {commune.name}
                                    </option>
                                ))}
                            </select>
                            {errors.commune && <div className="invalid-feedback">{errors.commune}</div>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">
                                Email
                            </label>
                            <input
                                type="email"
                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Entrez votre email"
                            />
                            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">
                                Mot de passe
                            </label>
                            <input
                                type="password"
                                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                placeholder="············"
                                
                            />
                            {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                        </div>

                        <button type="submit" className="btn btn-primary">
                            S'inscrire
                        </button>
                    </form>

                    {errors.general && (
                        <div className="alert alert-danger mt-3">
                            {errors.general}
                        </div>
                    )}

                    <p className="text-center">
                        <span>Vous avez déjà un compte ?</span>
                        <Link to="/">
                            <span> Connectez-vous</span>
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Register;
