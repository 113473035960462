import React, { useEffect, useState } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { Modal, Button } from 'react-bootstrap';
import myImage from '../images/1.png';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for react-toastify

const statusColors = {
    'Pending': 'orange',
    'Confirmed': 'blue',
    'Processing': 'yellow',
    'Ready for Packaging': 'violet',
    'Packaged': 'cyan',
    'Shipped': 'teal',
    'Out for Delivery': 'purple',
    'Delivered': 'green',
    'Completed': 'lightgreen',
    'Cancelled': 'grey',
    'Returned': 'pink',
    'Refunded': 'lightcoral',
    'Failed': 'red',
    'Awaiting Payment': 'blue'
};

function Order() {
    const [orders, setOrders] = useState([]);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState('');
    const [orderDate, setOrderDate] = useState('');
    const [packType, setPackType] = useState('');
    const [packTypes, setPackTypes] = useState([]);
    const [getstatus, setGetStatus] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showOrderDetailsModal, setShowOrderDetailsModal] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [newStatus, setNewStatus] = useState('');
    const [orderDetails, setOrderDetails] = useState(null);
    const [commune, setCommune] = useState({});
    const [province, setProvince] = useState({});
    const [region, setRegion] = useState({});
    const [statistics, setStatistics] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0);
    const [school, setSchool] = useState({});
    const [grade, setGrade] = useState({});
    const [showImageModal, setShowImageModal] = useState(false); // State for controlling image modal
    const [selectedImage, setSelectedImage] = useState(null); // State for storing the selected image
    const [filteredCategories, setFilteredCategories] = useState([]);
    const itemsPerPage = 10;


    // Fetch Pack Types with error handling
    const fetchPackTypes = async () => {
        try {
            const response = await axiosInstance.get('/pack-types');
            setPackTypes(response.data);
        } catch (error) {
            console.error('Error fetching pack types:', error);
            setError('Failed to fetch pack types.');
        }
    };

    // Fetch Statuses with error handling
    const fetchStatus = async () => {
        try {
            const response = await axiosInstance.get('/status');
            setGetStatus(response.data);
        } catch (error) {
            console.error('Error fetching status:', error);
            setError('Failed to fetch status.');
        }
    };

    // Fetch Orders with error handling
    const fetchOrders = async (page = 1) => {
        setLoading(true);
        setError('');
        try {
            const response = await axiosInstance.post('/orders', {
                page,
                status,
                orderDate,
                packTypeId: packType,
            });
            setOrders(response.data.data);
            setFilteredCategories(response.data.data);
            setCurrentPage(response.data.meta.current_page);
            setLastPage(response.data.meta.last_page);
        } catch (error) {
            setError('Failed to fetch orders.');
            console.error('Error fetching orders:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch Order Details only if not already fetched
    const fetchOrderDetails = async (orderId) => {
        try {
            const orderResponse = await axiosInstance.get(`/order/${orderId}`);
            setOrderDetails(orderResponse.data.data);
            if (orderResponse.data.data.communeId) {
                await getCommuneDetail(orderResponse.data.data.communeId);
            }
            if (orderResponse.data.data.schoolId) {
                await getSchoolDetail(orderResponse.data.data.schoolId);
            }
            if (orderResponse.data.data.gradeId) {
                await getGradeDetail(orderResponse.data.data.gradeId);
            }
        } catch (error) {
            console.error('Error fetching order details:', error);
            setError('Failed to fetch order details.');
        }
    };

    const getCommuneDetail = async (communeId) => {
        try {
            const response = await axiosInstance.get(`/getCommune/${communeId}`);
            setCommune(response.data.data);

            const provinceId = response.data.data.provinceId;
            if (provinceId) {
                await getProvinceDetail(provinceId);
            }
        } catch (error) {
            console.error('Error fetching commune details:', error);
        }
    };

    const getRegionDetail = async (regionId) => {
        try {
            const response = await axiosInstance.get(`/getRegion/${regionId}`);
            setRegion(response.data.data);
        } catch (error) {
            console.error('Error fetching region details:', error);
        }
    };

    const getProvinceDetail = async (provinceId) => {
        try {
            const response = await axiosInstance.get(`/getProvince/${provinceId}`);
            setProvince(response.data.data);

            const regionId = response.data.data.regionId;
            if (regionId) {
                await getRegionDetail(regionId);
            }
        } catch (error) {
            console.error('Error fetching province details:', error);
        }
    };

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setShowImageModal(true);
    };

    const handleImageModalClose = () => {
        setShowImageModal(false);
    };



    const getSchoolDetail = async (schoolId) => {
        try {
            const response = await axiosInstance.get(`/schoolsdata/${schoolId}`);
            setSchool(response.data.data);
        } catch (error) {
            console.error('Error fetching school details:', error);
        }
    };

    const getGradeDetail = async (gradeId) => {
        try {
            const response = await axiosInstance.get(`/grade/${gradeId}`);
            setGrade(response.data.data);
        } catch (error) {
            console.error('Error fetching grade details:', error);
        }
    };

    useEffect(() => {
        const fetchOrderStatistics = async () => {
            try {
                const response = await axiosInstance.get(`/orderStatistics`);
                setStatistics(response.data.data);
            } catch (error) {
                setError(error);
            }
        };

        fetchOrderStatistics();
    }, []);

    useEffect(() => {
        fetchPackTypes();
        fetchStatus();
    }, []);

    useEffect(() => {
        fetchOrders(currentPage);
    }, [currentPage, status, orderDate, packType]);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= lastPage) {
            setCurrentPage(page);
        }
    };

    const handleStatusChange = async (orderId, statusId) => {
        try {
            const response = await axiosInstance.post(`/orders/${orderId}/update-status`, {
                status: statusId,
            });

            if (response.data.status === 'success') {
                setShowSuccessModal(true);
                fetchOrders(currentPage);
            } else {
                setError('Failed to update order status.');
            }
        } catch (error) {
            setError('An error occurred while updating the order status.');
            console.error('Error updating order status:', error);
        }
    };

    const handleModalClose = () => {
        setShowSuccessModal(false);
        setShowOrderDetailsModal(false);
        setSelectedOrderId(null);
        setNewStatus('');
        setOrderDetails(null);
        setCommune({});
        setProvince({});
        setRegion({});
        setSchool({});
        setGrade({});
    };

    const handleOrderDetailsClick = async (orderId) => {
        setSelectedOrderId(orderId);
        setShowOrderDetailsModal(true);

        // Check if order details are already in state
        if (!orderDetails || orderDetails.id !== orderId) {
            await fetchOrderDetails(orderId);
        }
    };

    // Helper function to get the packType name by ID
    const getPackTypeName = (packTypeId) => {
        const packType = packTypes.find(pt => pt.id === packTypeId);
        return packType ? packType.name : 'Unknown';
    };

    const getStatusName = (statusId) => {
        const status = getstatus.find(st => st.id === statusId);
        return status ? status.name : 'Unknown';
    };

    const getStatusColor = (statusId) => {
        const status = getstatus.find(st => st.id === statusId); if (status) {
            return statusColors[status.name] || 'grey';
        }
        return 'grey';
    };
    const handleUpdateTotal = async () => {
        try {
            await axiosInstance.post(`/update-total/${orderDetails.id}`, {
                totalAmount: totalAmount
            });
            setShowSuccessModal(true);
        } catch (error) {
            console.error('Error updating total amount:', error);
            alert('Failed to update total amount');
        }
    };
 
    // pagination
    const renderPagination = () => {
        const pages = [];
    
        if (lastPage <= 4) {
            // Case when the total number of pages is less than or equal to 4
            for (let i = 1; i <= lastPage; i++) {
                pages.push(
                    <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
                        <button className="page-link" onClick={() => handlePageChange(i)}>
                            {i}
                        </button>
                    </li>
                );
            }
        } else if (currentPage === 1) {
            // Case for the first page, showing the first 4 pages and adding ellipsis if there are more pages
            for (let i = 1; i <= 4; i++) {
                pages.push(
                    <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
                        <button className="page-link" onClick={() => handlePageChange(i)}>
                            {i}
                        </button>
                    </li>
                );
            }
            if (lastPage > 4) {
                pages.push(
                    <li className="page-item disabled" key="ellipsis1">
                        <span className="page-link">...</span>
                    </li>
                );
                for (let i = lastPage - 2; i <= lastPage; i++) {
                    pages.push(
                        <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
                            <button className="page-link" onClick={() => handlePageChange(i)}>
                                {i}
                            </button>
                        </li>
                    );
                }
            }
        } else if (currentPage === lastPage) {
            // Case for the last page, showing the last 4 pages
            for (let i = lastPage - 3; i <= lastPage; i++) {
                pages.push(
                    <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
                        <button className="page-link" onClick={() => handlePageChange(i)}>
                            {i}
                        </button>
                    </li>
                );
            }
        } else {
            // Case for middle pages, showing 1 previous page, current page, and 2 next pages
            const start = Math.max(currentPage - 1, 1);  // Show one previous page
            const end = Math.min(currentPage + 2, lastPage);  // Show two pages after the current one
    
            if (start > 1) {
                pages.push(
                    <li className="page-item disabled" key="ellipsis1">
                        <span className="page-link">...</span>
                    </li>
                );
            }
    
            for (let i = start; i <= end; i++) {
                pages.push(
                    <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
                        <button className="page-link" onClick={() => handlePageChange(i)}>
                            {i}
                        </button>
                    </li>
                );
            }
    
            if (end < lastPage) {
                pages.push(
                    <li className="page-item disabled" key="ellipsis2">
                        <span className="page-link">...</span>
                    </li>
                );
            }
        }
    
        return (
            <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(1)}
                        disabled={currentPage === 1}
                    >
                        Premier
                    </button>
                </li>
    
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                </li>
    
                {pages}
    
                <li className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === lastPage}
                    >
                        Next
                    </button>
                </li>
    
                <li className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`}>
                    <button
                        className="page-link"
                        onClick={() => handlePageChange(lastPage)}
                        disabled={currentPage === lastPage}
                    >
                        Dernier
                    </button>
                </li>
            </ul>
        );
    };
       

    useEffect(() => {
        fetchOrders(currentPage);
        fetchPackTypes();
        fetchStatus();
    }, [currentPage]);
    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchOrders(currentPage);
        }, 2 * 60 * 1000); // 2 minutes

        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, [currentPage, status, orderDate, packType]);
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <div className="layout-page">
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="py-3 mb-4">
                            <span className="text-muted fw-light">Clients /</span> Liste des Commandes
                        </h4>

                        <div class="card mb-4">
                            <div class="card-widget-separator-wrapper">
                                <div class="card-body card-widget-separator">
                                    <div class="row gy-4 gy-sm-1">
                                        <div class="col-sm-6 col-lg-3">
                                            <div class="d-flex justify-content-between align-items-start card-widget-1 border-end pb-3 pb-sm-0">
                                                <div>
                                                    <h6 class="mb-2">Total des Commandes</h6>
                                                    <h4 class="mb-2">{statistics?.total_orders?.count ?? 0}</h4>
                                                    <p class="mb-0">
                                                        <span class="text-muted me-2">
                                                            {statistics?.total_orders?.revenue ?? 0}$
                                                        </span>
                                                        <span class="badge bg-label-success"></span>
                                                    </p>

                                                </div>
                                                <div class="avatar me-sm-4">
                                                    <span class="avatar-initial rounded bg-label-secondary">
                                                        <i class="bx bx-shopping-bag bx-sm"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            {/* <hr class="d-none d-sm-block d-lg-none me-4"> */}
                                        </div>
                                        <div class="col-sm-6 col-lg-3">
                                            <div class="d-flex justify-content-between align-items-start card-widget-2 border-end pb-3 pb-sm-0">
                                                <div>
                                                    <h6 class="mb-2">Commandes en Cours</h6>
                                                    <h4 class="mb-2">{statistics?.orders_in_progress?.count ?? 0}</h4>
                                                    <p class="mb-0">
                                                        <span class="text-muted me-2">
                                                            {statistics?.orders_in_progress?.count ?? 0}$
                                                        </span>
                                                        <span class="badge bg-label-success"></span>
                                                    </p>

                                                </div>
                                                <div class="avatar me-lg-4">
                                                    <span class="avatar-initial rounded bg-label-secondary">
                                                        <i class="bx bx-hourglass bx-sm"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            {/* <hr class="d-none d-sm-block d-lg-none"> */}
                                        </div>
                                        <div class="col-sm-6 col-lg-3">
                                            <div class="d-flex justify-content-between align-items-start border-end pb-3 pb-sm-0 card-widget-3">
                                                <div>
                                                    <h6 class="mb-2">Commandes Terminées</h6>
                                                    <h4 class="mb-2">{statistics?.completed_orders?.count ?? 0}</h4>
                                                    <p class="mb-0 text-muted">{statistics?.completed_orders?.revenue ?? 0}$</p>

                                                </div>
                                                <div class="avatar me-sm-4">
                                                    <span class="avatar-initial rounded bg-label-secondary">
                                                        <i class="bx bx-check-circle bx-sm"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-lg-3">
                                            <div class="d-flex justify-content-between align-items-start">
                                                <div>
                                                    <h6 class="mb-2">Valeur Moyenne de la Commande</h6>
                                                    <h4 class="mb-2">{statistics?.average_order_value ?? 0}</h4>
                                                    <p class="mb-0">
                                                        <span class="text-muted me-2"></span>
                                                        <span class="badge bg-label-danger"></span>
                                                    </p>

                                                </div>
                                                <div class="avatar">
                                                    <span class="avatar-initial rounded bg-label-secondary">
                                                        <i class="bx bx-bar-chart bx-sm"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header">
                                <h5 className="d-inline-block">Filtres de Recherche</h5>
                            </div>
                            <div className="card-body">
                                <div className="row gy-3">
                                    <div className="col-md-4">
                                        <label htmlFor="status" className="form-label">Statut de la Commande</label>
                                        <select id="status" className="form-select" value={status} onChange={(e) => setStatus(e.target.value)}>
                                            <option value="">All</option>
                                            {getstatus.map((status) => (
                                                <option key={status.id} value={status.id}>{status.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="orderDate" className="form-label">Date de la Commande</label>
                                        <input type="date" id="orderDate" className="form-control" value={orderDate} onChange={(e) => setOrderDate(e.target.value)} />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="packType" className="form-label">Type de Pack</label>
                                        <select id="packType" className="form-select" value={packType} onChange={(e) => setPackType(e.target.value)}>
                                            <option value="">All</option>
                                            {packTypes.map((packType) => (
                                                <option key={packType.id} value={packType.id}>{packType.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card mt-3">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th>Type de Pack</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {loading ? (
                            <tr>
                                <td colSpan="4" className="text-center">Loading...</td>
                            </tr>
                        ) : orders.length === 0 ? (
                            <tr>
                                <td colSpan="4" className="text-center">No orders found</td>
                            </tr>
                        ) : (
                            filteredCategories.map((order) => (
                                <tr key={order.id}>
                                    <td>{new Date(order.created_at).toLocaleString()}</td>
                                    <td>
                                        <span style={{
                                            backgroundColor: getStatusColor(order.status),
                                            padding: '5px 10px',
                                            color: '#fff',
                                            borderRadius: '5px',
                                        }}>
                                            {getStatusName(order.status)}
                                        </span>
                                    </td>
                                    <td>{getPackTypeName(order.packTypeId)}</td>
                                    <td>
                                        <button
                                            className="btn btn-sm btn-primary"
                                            onClick={() => handleOrderDetailsClick(order.id)}
                                        >
                                            Details
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                                    </tbody>
                                </table>
                            </div>

                            <div className="row mx-2 mt-4 justify-content-end">
                                <div className="col-sm-12 col-md-6 text-right">
                                    <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                                        Affichage de {((currentPage - 1) * itemsPerPage) + 1} à {Math.min(currentPage * itemsPerPage, filteredCategories.length)} sur {filteredCategories.length} entrées
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                                        {renderPagination()}
                                    </div>
                                </div>
                            </div>
                        </div>



                        {/* Success Modal */}
                        <Modal show={showSuccessModal} onHide={handleModalClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Statut Mis à Jour</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                L'opération est passé avec succées
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={handleModalClose}>
                                    Fermer
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* Order Details Modal */}
                        <Modal show={showOrderDetailsModal} onHide={handleModalClose} size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title>Détails de la Commande</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {orderDetails ? (
                                    <div>
                                        <h5>Détails du Client</h5>
                                        <div className='d-flex justify-content-start align-items-center mb-4'>
                                            <div className='avatar me-2'>
                                                <img src={myImage} alt='Avatar' className='rounded-circle' />
                                            </div>
                                            <div className='d-flex flex-column'>
                                                <div className='text-body text-nowrap fs-4'><strong> {orderDetails.buyerName} </strong></div>
                                            </div>
                                        </div>
                                        <h5>Informations de Contact</h5>
                                        <p>
                                            <strong>Téléphone: </strong>
                                            <a href={`tel:${orderDetails.buyerPhoneNumber}`}>
                                                {orderDetails.buyerPhoneNumber}
                                            </a>
                                        </p>

                                        <hr />
                                        <h5>Adresse de Livraison</h5>
                                        <p><strong>Commune :</strong> {commune.name || 'Loading...'}</p>
                                        <p><strong>Province : </strong>{province.name || 'Loading...'}</p>
                                        <p><strong>Region : </strong>{region.name || 'Loading...'}</p>
                                        <p><strong>Adresse de Livraison : </strong>{orderDetails.shippingAddress || 'Loading...'}</p>

                                        <hr />
                                        <h5>School Informations</h5>
                                        <p><strong>school :</strong> {school.schoolNameFr || 'Loading...'}</p>
                                        <p><strong>school adress : </strong>{school.schoolAddressFr || 'Loading...'}</p>
                                        <p><strong>contact number : </strong>{school.schoolContactNumber || 'Loading...'}</p>

                                        <hr />
                                        <h5>Grade Informations</h5>
                                        <p><strong>Grade name :</strong> {grade.name || 'Loading...'}</p>
                                        <hr />
                                        {/* <img
                                            src={`https://api.cartableweldi.com/public/images/${orderDetails.fornitureImage}`}
                                            alt="Order Image"
                                            style={{ width: '100px', height: '100px' }} // Set width and height as needed
                                        /> */}
                                        <img
                                            src={`https://api.cartableweldi.com/public/images/${orderDetails.fornitureImage}`}
                                            alt="Order Image"
                                            style={{ width: '100px', height: '100px', cursor: 'pointer' }} // Added cursor style
                                            onClick={() => handleImageClick(`https://api.cartableweldi.com/public/images/${orderDetails.fornitureImage}`)} // Call handleImageClick on click
                                        />

                                        <hr />
                                        <div className="col-md-4">
                                            <p><strong>total Amount : </strong>{orderDetails.totalAmount || 'Loading...'}</p>
                                            <label htmlFor="totalAmount" className="form-label">Update Total Amount</label>
                                            <input
                                                type="number"
                                                id="totalAmount"
                                                className="form-control"
                                                value={totalAmount}
                                                onChange={(e) => setTotalAmount(e.target.value)}
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            className="btn btn-primary mt-3"
                                            onClick={handleUpdateTotal}
                                        >
                                            Update Total
                                        </button>
                                        <hr />
                                        <div className="form-group">
                                            <label htmlFor="statusSelect">Mettre à Jour le Statut de la Commande</label>
                                            <select
                                                id="statusSelect"
                                                className="form-control"
                                                value={newStatus}
                                                onChange={(e) => setNewStatus(e.target.value)}
                                            >
                                                {getstatus.map(status => (
                                                    <option key={status.id} value={status.id}>
                                                        {status.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="text-center">Chargement des détails de la commande...</div>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleModalClose}>
                                    Fermer
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() => handleStatusChange(selectedOrderId, newStatus)}
                                >
                                    Mettre à Jour le Statut
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={showImageModal} onHide={() => setShowImageModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Image Preview</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <img
                                    src={selectedImage}
                                    alt="Enlarged Order Image"
                                    style={{ width: '100%', height: 'auto' }} // Full-width image
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleImageModalClose}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>


                        {error && <div className="alert alert-danger">{error}</div>}
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Order;