import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axiosInstance from '../utils/axiosInstance';
import { Link } from 'react-router-dom';

const Categories = () => {
   const [categories, setCategories] = useState([]);
   const [filteredCategories, setFilteredCategories] = useState([]);
   const [currentCategory, setCurrentCategory] = useState({ name: '' })
   const [showModal, setShowModal] = useState(false);
   const [modalMode, setModalMode] = useState(''); // 'create' or 'update' or 'delete'
   const [filter, setFilter] = useState({ name: '' })
   const [lastPage, setLastPage] = useState(1);

   const [currentPage, setCurrentPage] = useState(1);
   const itemsPerPage = 10;

   useEffect(() => {
      fetchCategories();
   }, []);

   // Fetch categories
   const fetchCategories = async () => {
      try {
         const response = await axiosInstance.get('/supply/categories');
         const data = response.data.data; // Access the data array in the response
         setCategories(data);
         setFilteredCategories(data);
      } catch (error) {
         console.error('Une erreur est survenue lors de la récupération des communes:', error);
      }
   }

   const handleFilterChange = (e) => {
      const { name, value } = e.target;
      setFilter({
         ...filter,
         [name]: value
      });
   };

   const handleFilterSubmit = (e) => {
      e.preventDefault();
      const filtered = categories.filter(categorie => {
         const isNameMatch = filter.name ? categorie.name.toLowerCase().includes(filter.name.toLowerCase()) : true;
         return isNameMatch;
      });

      setFilteredCategories(filtered);
      setCurrentPage(1); // Reset to first page after filtering
   };

   const getPaginatedData = () => {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return filteredCategories.slice(startIndex, endIndex);
   };

   const totalPages = Math.ceil(filteredCategories.length / itemsPerPage);

   const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
   };

   const renderPagination = () => {
      const pages = [];
  
      if (lastPage <= 4) {
          // Case when the total number of pages is less than or equal to 4
          for (let i = 1; i <= lastPage; i++) {
              pages.push(
                  <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
                      <button className="page-link" onClick={() => handlePageChange(i)}>
                          {i}
                      </button>
                  </li>
              );
          }
      } else if (currentPage === 1) {
          // Case for the first page, showing the first 4 pages and adding ellipsis if there are more pages
          for (let i = 1; i <= 4; i++) {
              pages.push(
                  <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
                      <button className="page-link" onClick={() => handlePageChange(i)}>
                          {i}
                      </button>
                  </li>
              );
          }
          if (lastPage > 4) {
              pages.push(
                  <li className="page-item disabled" key="ellipsis1">
                      <span className="page-link">...</span>
                  </li>
              );
              for (let i = lastPage - 2; i <= lastPage; i++) {
                  pages.push(
                      <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
                          <button className="page-link" onClick={() => handlePageChange(i)}>
                              {i}
                          </button>
                      </li>
                  );
              }
          }
      } else if (currentPage === lastPage) {
          // Case for the last page, showing the last 4 pages
          for (let i = lastPage - 3; i <= lastPage; i++) {
              pages.push(
                  <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
                      <button className="page-link" onClick={() => handlePageChange(i)}>
                          {i}
                      </button>
                  </li>
              );
          }
      } else {
          // Case for middle pages, showing 1 previous page, current page, and 2 next pages
          const start = Math.max(currentPage - 1, 1);  // Show one previous page
          const end = Math.min(currentPage + 2, lastPage);  // Show two pages after the current one
  
          if (start > 1) {
              pages.push(
                  <li className="page-item disabled" key="ellipsis1">
                      <span className="page-link">...</span>
                  </li>
              );
          }
  
          for (let i = start; i <= end; i++) {
              pages.push(
                  <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
                      <button className="page-link" onClick={() => handlePageChange(i)}>
                          {i}
                      </button>
                  </li>
              );
          }
  
          if (end < lastPage) {
              pages.push(
                  <li className="page-item disabled" key="ellipsis2">
                      <span className="page-link">...</span>
                  </li>
              );
          }
      }
  
      return (
          <ul className="pagination">
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button
                      className="page-link"
                      onClick={() => handlePageChange(1)}
                      disabled={currentPage === 1}
                  >
                      Premier
                  </button>
              </li>
  
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                  >
                      Previous
                  </button>
              </li>
  
              {pages}
  
              <li className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`}>
                  <button
                      className="page-link"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === lastPage}
                  >
                      Next
                  </button>
              </li>
  
              <li className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`}>
                  <button
                      className="page-link"
                      onClick={() => handlePageChange(lastPage)}
                      disabled={currentPage === lastPage}
                  >
                      Dernier
                  </button>
              </li>
          </ul>
      );
  };

   const toggleModal = (categorie = null, mode = '') => {
      setCurrentCategory(categorie);
      setModalMode(mode);
      setShowModal(!showModal);
   };

   const handleCreateOrUpdate = async () => {
      const url = modalMode === 'create'
         ? '/supply/categorie'
         : `/supply/categorie/${currentCategory.id}`;
      const method = modalMode === 'create' ? 'POST' : 'PUT';

      try {
         const data = { ...currentCategory };

         let response = await axiosInstance({
            method,
            url,
            data,
         });
         console.log(response)
         fetchCategories(); // Refresh the category list
      } catch (error) {
         console.error(`Une erreur est survenue lors de la ${modalMode === 'create' ? 'création' : 'mise à jour'} de l'école:`, error);
      }
      setShowModal(false);
   };

   const handleDelete = async () => {
      try {
         await axiosInstance.delete(`/supply/categorie/${currentCategory.id}`);
         fetchCategories(); // Refresh the category list
      } catch (error) {
         console.error('Une erreur est survenue lors de la suppression de l\'école:', error);
      }
      setShowModal(false);
   };

   const handleResetFilter = () => {
      setFilter({
         name: ''
      });
      setFilteredCategories(categories);
      setCurrentPage(1); // Reset to first page after resetting
   };

   return (
      <div className="layout-wrapper layout-content-navbar">
         <div className="layout-container">
            <div className="layout-page">
               <div className="container-xxl flex-grow-1 container-p-y">
                  <h4 className="py-3 mb-4">
                     <span className="text-muted fw-light">Supply /</span> Categories
                  </h4>
                  <div className="card">
                     <div className="card-datatable table-responsive">
                        <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                           <div className="row mx-2 mb-2 mt-4">
                              <div className="col-md-12 mt-2">
                                 <form onSubmit={handleFilterSubmit}>
                                    <div className="row g-3">
                                       <div className="d-flex align-items-end justify-content-end">
                                          <button
                                             type="button"
                                             className="btn btn-primary me-3"
                                             onClick={() => toggleModal(null, 'create')}
                                          >
                                             <i className="bx bx-plus"></i> Ajouter nouveau
                                          </button>
                                       </div>
                                       <div className="col-md-2">
                                          <label htmlFor="schoolNameFr" className="form-label">Nom</label>
                                          <input
                                             type="search"
                                             id="schoolNameFr"
                                             name="name"
                                             className="form-control"
                                             placeholder="Nom"
                                             value={filter.name}
                                             onChange={handleFilterChange}
                                          />
                                       </div>

                                       <div className="d-flex align-items-end justify-content-end">
                                          <button type="submit" className="btn btn-outline-secondary me-3">
                                             <i className="bx bx-search"></i> Rechercher
                                          </button>
                                          <button type="button" className="btn btn-outline-danger" onClick={handleResetFilter}>
                                             <i className="bx bx-reset"></i> Réinitialiser le filtre
                                          </button>
                                       </div>
                                    </div>
                                 </form>
                              </div>
                           </div>
                           <table
                              className="datatables-grades table border-top dataTable no-footer dtr-column"
                              id="DataTables_Table_0"
                              style={{ width: '100%' }}
                           >
                              <thead>
                                 <tr>
                                    <th>Nom</th>
                                    <th>Actions</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {Array.isArray(filteredCategories) && getPaginatedData().length > 0 ? (
                                    getPaginatedData().map((categorie) => (
                                       <tr key={categorie.id}>
                                          <td>{categorie.name}</td>
                                          <td className="text-start">
                                             <div className='d-inline-block'>
                                                <Link to={`${categorie.id}/subcategories`}>subCategories</Link>
                                             </div>
                                             <div className="d-inline-block text-nowrap">
                                                <button className="btn btn-sm btn-icon" onClick={() => toggleModal(categorie, 'update')} title="Modifier">
                                                   <i className="bx bx-edit"></i>
                                                </button>
                                                <button className="btn btn-sm btn-icon" onClick={() => toggleModal(categorie, 'delete')} title="Supprimer">
                                                   <i className="bx bx-trash"></i>
                                                </button>
                                             </div>
                                          </td>
                                       </tr>
                                    ))
                                 ) : (
                                    <tr>
                                       <td colSpan="9">Aucune categorie trouvée.</td>
                                    </tr>
                                 )}
                              </tbody>
                           </table>
                           <div className="row mx-2 mt-4 justify-content-end">
                              <div className="col-sm-12 col-md-6 text-right">
                                 <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                                    Affichage de {((currentPage - 1) * itemsPerPage) + 1} à {Math.min(currentPage * itemsPerPage, filteredCategories.length)} sur {filteredCategories.length} entrées
                                 </div>
                              </div>
                              <div className="col-sm-12 col-md-6">
                                 <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                                    {renderPagination()}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {/* Modal */}
         <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
               <Modal.Title>
                  {modalMode === 'create' ? 'Ajouter Categorie' : modalMode === 'update' ? 'Modifier Categorie' : 'Supprimer Categorie'}
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               {modalMode === 'delete' ? (
                  <p>Êtes-vous sûr de vouloir supprimer cette categorie?</p>
               ) : (
                  <div>
                     <div className="form-group">
                        <label htmlFor="schoolNameFr">Nom :</label>
                        <input
                           type="text"
                           className="form-control"
                           id="schoolNameFr"
                           placeholder="Nom (FR)"
                           value={currentCategory?.name || ''}
                           onChange={(e) => setCurrentCategory({ ...currentCategory, name: e.target.value })}
                        />
                     </div>
                  </div>
               )}
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={() => setShowModal(false)}>
                  Fermer
               </Button>
               {modalMode === 'delete' ? (
                  <Button variant="danger" onClick={handleDelete}>
                     Supprimer
                  </Button>
               ) : (
                  <Button variant="primary" onClick={handleCreateOrUpdate}>
                     {modalMode === 'create' ? 'Ajouter' : 'Modifier'}
                  </Button>
               )}
            </Modal.Footer>
         </Modal>
      </div>
   );
};

export default Categories;

