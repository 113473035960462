// import React, { useEffect, useState } from 'react';
// import axiosInstance from '../utils/axiosInstance';
// import { Modal, Button, Alert } from 'react-bootstrap';

// function Users() {
//   const [users, setUsers] = useState([]);
//   const [roles, setRoles] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');  // State for success messages
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [showUserModal, setShowUserModal] = useState(false);
//   const [isEditMode, setIsEditMode] = useState(false);
//   const [showDeleteModal, setShowDeleteModal] = useState(false);

//   // Fetch Users with error handling
//   const fetchUsers = async () => {
//     setLoading(true);
//     try {
//       const response = await axiosInstance.get('/users');
//       setUsers(response.data.data);
//       setSuccess('');  // Clear any previous success message
//       setError('');  // Clear any previous error message
//     } catch (error) {
//       if (error.response && error.response.status === 401) {
//         setError('You are not authorized to access this resource.');
//       } else {
//         setError('Failed to fetch users.');
//       }
//       console.error('Error fetching users:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Fetch Roles with error handling
//   const fetchRoles = async () => {
//     try {
//       const response = await axiosInstance.get('/roles');
//       setRoles(response.data.data);
//     } catch (error) {
//       setError('Failed to fetch roles.');
//       console.error('Error fetching roles:', error);
//     }
//   };

//   useEffect(() => {
//     fetchUsers();
//     fetchRoles();
//   }, []);

//   const handleUserModalOpen = (user = null) => {
//     setSelectedUser(user || {
//       name: '',
//       email: '',
//       phone_number: '',
//       password: '',
//       isActive: true,
//       roles: []
//     });
//     setIsEditMode(!!user);
//     setShowUserModal(true);
//   };

//   const handleUserModalClose = () => {
//     setShowUserModal(false);
//     setSelectedUser(null);
//   };

//   const handleSaveUser = async () => {
//     try {
//       const userData = {
//         ...selectedUser,
//         isActive: selectedUser.isActive ? 1 : 0,
//         roles: selectedUser.roles
//       };

//       if (isEditMode) {
//         await axiosInstance.put(`/user/${selectedUser.id}`, userData);
//         setSuccess('User updated successfully.');
//       } else {
//         await axiosInstance.post('/users', userData);
//         setSuccess('User added successfully.');
//       }

//       fetchUsers();
//       handleUserModalClose();
//     } catch (error) {
//       setError('Failed to save user.');
//       console.error('Error saving user:', error);
//     }
//   };

//   const handleDeleteUser = async () => {
//     try {
//       await axiosInstance.delete(`/user/${selectedUser.id}`);
//       setSuccess('User deleted successfully.');
//       fetchUsers();
//       handleDeleteModalClose();  // Close the delete modal after deletion
//     } catch (error) {
//       setError('Failed to delete user.');
//       console.error('Error deleting user:', error);
//     }
//   };

//   const handleDeleteModalOpen = (user) => {
//     setSelectedUser(user);
//     setShowDeleteModal(true);
//   };

//   const handleDeleteModalClose = () => {
//     setShowDeleteModal(false);
//     setSelectedUser(null);
//   };

//   return (
//     <div className="layout-wrapper layout-content-navbar">
//       <div className="layout-container">
//         <div className="layout-page">
//           <div className="container-xxl flex-grow-1 container-p-y">
//             <h4 className="py-3 mb-4">
//               <span className="text-muted fw-light">Admin /</span> User Management
//             </h4>

//             {/* Feedback Messages */}
//             {error && <Alert variant="danger" onClose={() => setError('')} dismissible>{error}</Alert>}
//             {success && <Alert variant="success" onClose={() => setSuccess('')} dismissible>{success}</Alert>}

//             <div className="mb-3">
//               <Button onClick={() => handleUserModalOpen()}>Add New User</Button>
//             </div>

//             <div className="card mt-3">
//               <div className="table-responsive">
//                 <table className="table">
//                   <thead>
//                     <tr>
//                       <th>Name</th>
//                       <th>Email</th>
//                       <th>Phone Number</th>
//                       <th>Active</th>
//                       <th>Action</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {loading ? (
//                       <tr>
//                         <td colSpan="6" className="text-center">Loading...</td>
//                       </tr>
//                     ) : users.length === 0 ? (
//                       <tr>
//                         <td colSpan="6" className="text-center">No users found</td>
//                       </tr>
//                     ) : users.map((user) => (
//                       <tr key={user.id}>
//                         <td>{user.name}</td>
//                         <td>{user.email}</td>
//                         <td>{user.phone_number}</td>
//                         <td>{user.isActive ? 'Yes' : 'No'}</td>
//                         <td>
//                           <button className="btn btn-sm btn-primary" onClick={() => handleUserModalOpen(user)}>
//                             Edit
//                           </button>
//                           <button className="btn btn-sm btn-danger" onClick={() => handleDeleteModalOpen(user)}>
//                             Delete
//                           </button>
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </div>
//             </div>

//             {/* User Modal */}
//             <Modal show={showUserModal} onHide={handleUserModalClose}>
//               <Modal.Header closeButton>
//                 <Modal.Title>{isEditMode ? 'Edit User' : 'Add User'}</Modal.Title>
//               </Modal.Header>
//               <Modal.Body>
//                 {/* Form Fields */}
//                 <div className="form-group">
//                   <label>Name</label>
//                   <input type="text" className="form-control" value={selectedUser?.name || ''} onChange={(e) => setSelectedUser({ ...selectedUser, name: e.target.value })} />
//                 </div>
//                 <div className="form-group">
//                   <label>Email</label>
//                   <input type="email" className="form-control" value={selectedUser?.email || ''} onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })} />
//                 </div>
//                 <div className="form-group">
//                   <label>Phone Number</label>
//                   <input type="text" className="form-control" value={selectedUser?.phone_number || ''} onChange={(e) => setSelectedUser({ ...selectedUser, phone_number: e.target.value })} />
//                 </div>
//                 <div className="form-group">
//                   <label>Password</label>
//                   <input type="password" className="form-control" value={selectedUser?.password || ''} onChange={(e) => setSelectedUser({ ...selectedUser, password: e.target.value })} />
//                 </div>
//                 <div className="form-group">
//                   <label>Roles</label>
//                   <select
//                     className="form-control"
//                     multiple
//                     value={selectedUser?.roles || []}
//                     onChange={(e) =>
//                       setSelectedUser({
//                         ...selectedUser,
//                         roles: [...e.target.selectedOptions].map(option => parseInt(option.value, 10))
//                       })
//                     }
//                   >
//                     {roles.map((role) => (
//                       <option key={role.id} value={role.id}>{role.name}</option>
//                     ))}
//                   </select>
//                 </div>
//                 <br />
//                 <div className="form-group">
//                   <label>Active</label>
//                   <input type="checkbox" checked={selectedUser?.isActive || false} onChange={(e) => setSelectedUser({ ...selectedUser, isActive: e.target.checked })} />
//                 </div>
//               </Modal.Body>
//               <Modal.Footer>
//                 <Button variant="secondary" onClick={handleUserModalClose}>
//                   Cancel
//                 </Button>
//                 <Button variant="primary" onClick={handleSaveUser}>
//                   Save
//                 </Button>
//               </Modal.Footer>
//             </Modal>

//             {/* Delete Confirmation Modal */}
//             <Modal show={showDeleteModal} onHide={handleDeleteModalClose}>
//               <Modal.Header closeButton>
//                 <Modal.Title>Confirm Deletion</Modal.Title>
//               </Modal.Header>
//               <Modal.Body>
//                 Are you sure you want to delete {selectedUser?.name}? This action cannot be undone.
//               </Modal.Body>
//               <Modal.Footer>
//                 <Button variant="secondary" onClick={handleDeleteModalClose}>
//                   Cancel
//                 </Button>
//                 <Button variant="danger" onClick={handleDeleteUser}>
//                   Delete
//                 </Button>
//               </Modal.Footer>
//             </Modal>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Users;


import React, { useEffect, useState } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { Modal, Button, Alert, Table, Badge } from 'react-bootstrap';

function Users() {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [showUserModal, setShowUserModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/users');
      setUsers(response.data.data);
      setSuccess('');
      setError('');
    } catch (error) {
      setError('Failed to fetch users.');
    } finally {
      setLoading(false);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await axiosInstance.get('/roles');
      setRoles(response.data.data);
    } catch (error) {
      setError('Failed to fetch roles.');
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchRoles();
  }, []);

  const handleUserModalOpen = (user = null) => {
    setSelectedUser(
      user || {
        name: '',
        email: '',
        phone_number: '',
        password: '', // Make sure password is always empty
        isActive: true,
        roles: [],
      }
    );
    setIsEditMode(!!user);
    setShowUserModal(true);
  };

  const handleUserModalClose = () => {
    setShowUserModal(false);
    setSelectedUser(null);
  };

  const handleSaveUser = async () => {
    try {
      const userData = {
        ...selectedUser,
        isActive: selectedUser.isActive ? 1 : 0,
        roles: selectedUser.roles,
      };

      if (isEditMode) {
        await axiosInstance.put(`/user/${selectedUser.id}`, userData);
        setSuccess('User updated successfully.');
      } else {
        await axiosInstance.post('/users', userData);
        setSuccess('User added successfully.');
      }

      fetchUsers();
      handleUserModalClose();
    } catch (error) {
      setError('Failed to save user.');
    }
  };

  const handleDeleteUser = async () => {
    try {
      await axiosInstance.delete(`/user/${selectedUser.id}`);
      setSuccess('User deleted successfully.');
      fetchUsers();
      handleDeleteModalClose();
    } catch (error) {
      setError('Failed to delete user.');
    }
  };

  const handleDeleteModalOpen = (user) => {
    setSelectedUser(user);
    setShowDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
    setSelectedUser(null);
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <div className="layout-page">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="py-3 mb-4">
              <span className="text-muted fw-light">Admin /</span> User Management
            </h4>

            {error && <Alert variant="danger" onClose={() => setError('')} dismissible>{error}</Alert>}
            {success && <Alert variant="success" onClose={() => setSuccess('')} dismissible>{success}</Alert>}

            <div className="mb-3">
              <Button onClick={() => handleUserModalOpen()}>Add New User</Button>
            </div>

            <div className="card mt-3">
              <div className="table-responsive">
                <Table striped hover> {/* Removed 'bordered' */}
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Active</th>
                      <th>Roles</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="6" className="text-center">Loading...</td>
                      </tr>
                    ) : users.length === 0 ? (
                      <tr>
                        <td colSpan="6" className="text-center">No users found</td>
                      </tr>
                    ) : users.map((user) => (
                      <tr key={user.id}>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.phone_number}</td>
                        <td>{user.isActive ? 'Yes' : 'No'}</td>
                        <td>
                          {user.roles && user.roles.length > 0 ? (
                            user.roles.map((role) => (
                              <Badge key={role.id} pill bg="primary" className="me-1">
                                {role.name}
                              </Badge>
                            ))
                          ) : (
                            <span>No roles</span>
                          )}
                        </td>
                        <td>
                          <Button size="sm" variant="primary" onClick={() => handleUserModalOpen(user)}>
                            Edit
                          </Button>{' '}
                          <Button size="sm" variant="danger" onClick={() => handleDeleteModalOpen(user)}>
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>

            {/* User Modal */}
            <Modal show={showUserModal} onHide={handleUserModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>{isEditMode ? 'Edit User' : 'Add User'}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={selectedUser?.name || ''}
                    onChange={(e) => setSelectedUser({ ...selectedUser, name: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    value={selectedUser?.email || ''}
                    onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={selectedUser?.phone_number || ''}
                    onChange={(e) => setSelectedUser({ ...selectedUser, phone_number: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    className="form-control"
                    value={selectedUser?.password || ''}
                    onChange={(e) => setSelectedUser({ ...selectedUser, password: e.target.value })}
                    placeholder={isEditMode ? 'Leave blank to keep unchanged' : ''}
                  />
                </div>
                <div className="form-group">
                  <label>Roles</label>
                  <select
                    className="form-control"
                    multiple
                    value={selectedUser?.roles || []}
                    onChange={(e) =>
                      setSelectedUser({
                        ...selectedUser,
                        roles: [...e.target.selectedOptions].map(option => parseInt(option.value, 10))
                      })
                    }
                  >
                    {roles.map((role) => (
                      <option key={role.id} value={role.id}>{role.name}</option>
                    ))}
                  </select>
                </div>
                <br />
                <div className="form-group">
                  <label>Active</label>
                  <input
                    type="checkbox"
                    checked={selectedUser?.isActive || false}
                    onChange={(e) => setSelectedUser({ ...selectedUser, isActive: e.target.checked })}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleUserModalClose}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={handleSaveUser}>
                  Save
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal show={showDeleteModal} onHide={handleDeleteModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>Confirm Deletion</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to delete {selectedUser?.name}? This action cannot be undone.
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleDeleteModalClose}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={handleDeleteUser}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
