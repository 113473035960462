// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Navbar from './components/NavBar';
// import Sidebar from './components/SideBar';
// import Organisations from './pages/Schools';
// import Home from './pages/Home';
// import Login from './components/Login';
// import './index.css';
// import Users from './pages/Users';

// const Layout = ({ children }) => (
//   <div className="layout-wrapper layout-content-navbar">
//     <div className="layout-container">
//       <Sidebar />
//       <div className="layout-page">
//         <Navbar />
//         <div className="content-wrapper">
//           {children}
//         </div>
//       </div>
//     </div>
//     <div className="layout-overlay layout-menu-toggle"></div>
//     <div className="drag-target"></div>
//   </div>
// );

// const App = () => {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/login" element={<Login />} />
//         <Route path="/" element={<Layout><Home /></Layout>} />
//         <Route path="/admin/Schools" element={<Layout><Organisations /></Layout>} />
//         <Route path="/admin/users" element={<Layout><Users /></Layout>} />
//       </Routes>
//     </Router>
//   );
// };

// export default App;


import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/NavBar';
import Sidebar from './components/SideBar';
import Schools from './pages/Schools';
import Home from './pages/Home';
import Login from './components/Login';
import './index.css';
import Users from './pages/Users';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import { Outlet } from 'react-router-dom';
import Order from './pages/Order';
import Categories from './pages/Categories';
import SubCategories from './pages/SubCategories';
import Attributes from './pages/Attributes';
import ContactUs from './pages/ContactUs';
import Donation from './pages/Donation';
import Partnerships from './pages/Partnership';
import Register from './components/Register';
import Livreur from './pages/Livreur';
import RoleBasedRoute from './components/RoleBasedRoute'; // Import the RoleBasedRoute

const Layout = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  return (
    <div className={`layout-wrapper layout-content-navbar ${isSidebarVisible ? 'sidebar-visible' : ''}`}>
      <div className="layout-container">
        <Sidebar isSidebarVisible={isSidebarVisible} toggleSidebar={toggleSidebar} />
        <div className="layout-page">
          <Navbar toggleSidebar={toggleSidebar} />
          <div className="content-wrapper">
            <Outlet />
          </div>
        </div>
      </div>
      {isSidebarVisible && (
        <div className="layout-overlay" onClick={toggleSidebar}></div>
      )}
      <div className="drag-target"></div>
    </div>
  );
};


const App = () => {
  return (
    <AuthProvider>
      <Router>
      <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="admin/schools"
            element={<RoleBasedRoute roles={['ADMIN']} element={<Schools />} />}/>
            {/* Use RoleBasedRoute to protect Users page */}
            <Route 
              path="admin/users" 
              element={<RoleBasedRoute roles={['SUPER_ADMIN']} element={<Users />} />} 
            />
            <Route path="admin/orders" element={<RoleBasedRoute roles={['ADMIN']} element={<Order />} />} />
            <Route 
                path="admin/contactUs" 
                element={<RoleBasedRoute roles={['ADMIN']} element={<ContactUs />} />} 
              />

              <Route 
                path="admin/donations" 
                element={<RoleBasedRoute roles={['ADMIN']} element={<Donation />} />} 
              />

              <Route 
                path="admin/partnerships" 
                element={<RoleBasedRoute roles={['ADMIN']} element={<Partnerships />} />} 
              />
              <Route 
                path="admin/categories" 
                element={<RoleBasedRoute roles={['ITEMS_MANAGER']} element={<Categories />} />} 
              />

              <Route 
                path="admin/categories/:categoryId/subcategories" 
                element={<RoleBasedRoute roles={['ITEMS_MANAGER']} element={<SubCategories />} />} 
              />

              <Route 
                path="/admin/attributes" 
                element={<RoleBasedRoute roles={['ITEMS_MANAGER']} element={<Attributes />} />} 
              />

            <Route path="/admin/livreur"
            element={<RoleBasedRoute roles={['LIVREUR']} element={<Livreur />} />} />
          </Route>
          </Route>
        </Routes>
        {/* <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="admin/schools" element={<Schools />} />
              <Route path="admin/users" element={<Users />} roles={['SCHOOL_ADMIN']}/>
              <Route path="admin/orders" element={<Order />} />
              <Route path="admin/contactUs" element={<ContactUs />} />
              <Route path="admin/donations" element={<Donation />} />
              <Route path="admin/partnerships" element={<Partnerships />} />
              <Route path="admin/categories" element={<Categories />} />
              <Route path="admin/categories/:categoryId/subcategories" element={<SubCategories />} />
              <Route path="/admin/attributes" element={<Attributes />} />
              <Route path="/admin/livreur" element={<Livreur />} />
            </Route>
          </Route>
        </Routes> */}
      </Router>
    </AuthProvider>
  );
};

export default App;


