
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import HarmoniaLogo from '../images/register.jpg'; 
import LoginImage from '../images/web_logo_dark.png';
import './Login.css';
import axiosInstance from '../utils/axiosInstance';
import AuthContext from '../context/AuthContext';
import { Link } from 'react-router-dom';

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { login, error } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    try {
      const response = await axiosInstance.post('/user/login', {
        phone_number: phoneNumber,
        password,
      });
  
      const data = response.data;
  
      if (response.status === 200 && data.status === "success") {
        await login(data.data.token);
        if (!error) {
          navigate('/');
        }
      } else {
        setErrors(data.errors || { general: 'Login failed' });
      }
    } catch (error) {
      setErrors({ general: 'An error occurred. Please try again.' });
    }
  };
  
  

  return (
    <div className="authentication-wrapper">
      <div
        className="background-section"
        style={{
          backgroundImage: `url(${HarmoniaLogo})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      ></div>

      <div className="login-section">
        <div className="form-container">
          <div className="app-brand mb-5 d-flex justify-content-center">
            <a href="/" className="app-brand-link gap-2">
              <span className="app-brand-logo demo mobile-logo">
                 <img
                  src={LoginImage}
                  className="img-fluid"
                  alt="Image de connexion"
                  width="200"
                />
              </span>
            </a>
          </div>

          <h4 className="mb-2">Bienvenue dans Cartable Weldi</h4>
          <p className="mb-4">Veuillez vous connecter à votre compte et commencer l'aventure</p>

          <form className="mb-3" onSubmit={handleSubmit} noValidate>
            <div className="mb-3">
              <label htmlFor="phone_number" className="form-label">
                Numéro de téléphone
              </label>
              <input
                type="text"
                className={`form-control ${errors.phone_number ? 'is-invalid' : ''}`}
                id="phone_number"
                name="phone_number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Entrez votre numéro de téléphone"
                required
                autoFocus
              />
              {errors.phone_number && <div className="invalid-feedback">{errors.phone_number}</div>}
            </div>

            <div className="mb-3 form-password-toggle">
              <div className="d-flex justify-content-between">
                <label className="form-label" htmlFor="password">
                  Mot de passe
                </label>
                <a>
                  <small>Mot de passe oublié ?</small>
                </a>
              </div>
              <div className="mb-3 form-password-toggle fv-plugins-icon-container">
                <div className="input-group input-group-merge has-validation">
                  <input
                    type="password"
                    id="password"
                    className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="············"
                    required
                    autoComplete="current-password"
                  />
                  {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                </div>
                <input type="hidden" id="general" className={`form-control ${errors.general ? 'is-invalid' : ''}`} />
                {errors.general && <div className="invalid-feedback">{errors.general}</div>}
              </div>
            </div>

            <div className="mb-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="remember_me"
                  name="remember"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="remember_me">
                  Se souvenir de moi
                </label>
              </div>
            </div>

            <button className="btn btn-primary d-grid w-100" type="submit">
              Se connecter
            </button>
          </form>

          {error && <div className="alert alert-danger mt-3">{error}</div>}

          {/* <p className="text-center">
            <span>Nouveau sur notre plateforme ?</span>
            <Link to="/register">
              <span>Créer un compte</span>
            </Link>
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
