

import React, { createContext, useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setLoading(false);
        return;
      }

      try {
        const response = await axiosInstance.get('/authenticated-user', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setUser(response.data.data); // Adjust based on your API response structure
          setIsAuthenticated(true);
          setError(null); // Clear any previous error
        } else {
          throw new Error('Unauthorized');
        }
      } catch (error) {
        logout(); // Logout if an error occurs
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  const login = async (token) => {
    localStorage.setItem('token', token);
    setLoading(true); // Set loading to true when starting login process
    try {
      const response = await axiosInstance.get('/authenticated-user', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setUser(response.data.data); // Adjust based on your API response structure
        setIsAuthenticated(true);
        setError(null); // Clear any previous error
      } else {
        throw new Error('Unauthorized');
      }
    } catch (error) {
      setIsAuthenticated(false);
    } finally {
      setLoading(false); // Stop loading state after login attempt
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
    setIsAuthenticated(false);
    setError(null); // Clear any previous error
  };

  return (
    <AuthContext.Provider value={{ loading, isAuthenticated, user, login, logout, error }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
