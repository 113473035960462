import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axiosInstance from '../utils/axiosInstance';
import { Link, useParams } from 'react-router-dom';

const SubCategories = () => {
   //* get the categorie id
   const { categoryId } = useParams();
   const [subCategories, setSubCategories] = useState([]);
   const [filteredSubCategories, setFilteredSubCategories] = useState([]);
   const [currentSubCategory, setCurrentSubCategory] = useState({ name: '', categoryId: categoryId })
   const [showModal, setShowModal] = useState(false);
   const [modalMode, setModalMode] = useState(''); // 'create' or 'update' or 'delete'
   const [filter, setFilter] = useState({ name: '' })

   const [currentPage, setCurrentPage] = useState(1);
   const itemsPerPage = 10;

   useEffect(() => {
      fetchSubCategories();
   }, []);

   // Fetch subCategories
   const fetchSubCategories = async () => {
      try {
         const response = await axiosInstance.get('/supply/subcategories/' + categoryId);
         const data = response.data.data; // Access the data array in the response
         setSubCategories(data);
         setFilteredSubCategories(data);
         console.log(data)
      } catch (error) {
         console.error('Une erreur est survenue lors de la récupération des communes:', error);
      }
   }

   const handleFilterChange = (e) => {
      const { name, value } = e.target;
      setFilter({
         ...filter,
         [name]: value
      });
   };

   const handleFilterSubmit = (e) => {
      e.preventDefault();
      const filtered = subCategories.filter(subCategorie => {
         const isNameMatch = filter.name ? subCategorie.name.toLowerCase().includes(filter.name.toLowerCase()) : true;
         return isNameMatch;
      });

      setFilteredSubCategories(filtered);
      setCurrentPage(1); // Reset to first page after filtering
   };

   const getPaginatedData = () => {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return filteredSubCategories.slice(startIndex, endIndex);
   };

   const totalPages = Math.ceil(filteredSubCategories.length / itemsPerPage);

   const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
   };

   const renderPagination = () => {
      const pages = [];
      const start = Math.max(currentPage - 2, 1);
      const end = Math.min(start + 4, totalPages);

      for (let i = start; i <= end; i++) {
         pages.push(
            <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
               <button className="page-link" onClick={() => handlePageChange(i)}>
                  {i}
               </button>
            </li>
         );
      }

      return (
         <div className="">
            <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
               <nav aria-label="Page navigation">
                  <ul className="pagination justify-content-end">
                     {/* First Button */}
                     <li className={`page-item ${currentPage > 1 ? '' : 'disabled'}`} id="DataTables_Table_0_first">
                        <button className="page-link" onClick={() => handlePageChange(1)} disabled={currentPage <= 1}>
                           <i className="tf-icon bx bx-chevrons-left"></i> Premier
                        </button>
                     </li>

                     {/* Previous Button */}
                     <li className={`page-item ${currentPage > 1 ? '' : 'disabled'}`} id="DataTables_Table_0_previous">
                        <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage <= 1}>
                           <i className="tf-icon bx bx-chevron-left"></i> Précédent
                        </button>
                     </li>

                     {/* Page Numbers */}
                     {pages}

                     {/* Next Button */}
                     <li className={`page-item ${currentPage < totalPages ? '' : 'disabled'}`} id="DataTables_Table_0_next">
                        <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage >= totalPages}>
                           Suivant <i className="tf-icon bx bx-chevron-right"></i>
                        </button>
                     </li>

                     {/* Last Button */}
                     <li className={`page-item ${currentPage < totalPages ? '' : 'disabled'}`} id="DataTables_Table_0_last">
                        <button className="page-link" onClick={() => handlePageChange(totalPages)} disabled={currentPage >= totalPages}>
                           Dernier <i className="tf-icon bx bx-chevrons-right"></i>
                        </button>
                     </li>
                  </ul>
               </nav>
            </div>
         </div>
      );
   };

   const toggleModal = (subCategorie = null, mode = '') => {
      setCurrentSubCategory(subCategorie);
      setModalMode(mode);
      setShowModal(!showModal);
   };

   const handleCreateOrUpdate = async () => {
      const url = modalMode === 'create'
         ? '/supply/subcategorie'
         : `/supply/subcategorie/${currentSubCategory.id}`;
      const method = modalMode === 'create' ? 'POST' : 'PUT';

      try {
         const data = { ...currentSubCategory, categoryId: parseInt(categoryId) };

         let response = await axiosInstance({
            method,
            url,
            data,
         });
         fetchSubCategories(); // Refresh the category list
      } catch (error) {
         console.error(`Une erreur est survenue lors de la ${modalMode === 'create' ? 'création' : 'mise à jour'} de l'école:`, error);
      }
      setShowModal(false);
   };

   const handleDelete = async () => {
      try {
         let response = await axiosInstance.delete(`/supply/subcategorie/${currentSubCategory.id}`);
         fetchSubCategories(); // Refresh the category list
      } catch (error) {
         console.error('Une erreur est survenue lors de la suppression de l\'école:', error);
      }
      setShowModal(false);
   };

   const handleResetFilter = () => {
      setFilter({
         name: ''
      });
      setFilteredSubCategories(subCategories);
      setCurrentPage(1); // Reset to first page after resetting
   };

   return (
      <div className="layout-wrapper layout-content-navbar">
         <div className="layout-container">
            <div className="layout-page">
               <div className="container-xxl flex-grow-1 container-p-y">
                  <h4 className="py-3 mb-4">
                     <span className="text-muted fw-light">Supply /</span> Categories / subcategories
                  </h4>
                  <div className="card">
                     <div className="card-datatable table-responsive">
                        <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                           <div className="row mx-2 mb-2 mt-4">
                              <div className="col-md-12 mt-2">
                                 <form onSubmit={handleFilterSubmit}>
                                    <div className="row g-3">
                                       <div className="d-flex align-items-end justify-content-end">
                                          <button
                                             type="button"
                                             className="btn btn-primary me-3"
                                             onClick={() => toggleModal(null, 'create')}
                                          >
                                             <i className="bx bx-plus"></i> Ajouter nouveau
                                          </button>
                                       </div>
                                       <div className="col-md-2">
                                          <label htmlFor="schoolNameFr" className="form-label">Nom</label>
                                          <input
                                             type="search"
                                             id="schoolNameFr"
                                             name="name"
                                             className="form-control"
                                             placeholder="Nom"
                                             value={filter.name}
                                             onChange={handleFilterChange}
                                          />
                                       </div>

                                       <div className="d-flex align-items-end justify-content-end">
                                          <button type="submit" className="btn btn-outline-secondary me-3">
                                             <i className="bx bx-search"></i> Rechercher
                                          </button>
                                          <button type="button" className="btn btn-outline-danger" onClick={handleResetFilter}>
                                             <i className="bx bx-reset"></i> Réinitialiser le filtre
                                          </button>
                                       </div>
                                    </div>
                                 </form>
                              </div>
                           </div>
                           <table
                              className="datatables-grades table border-top dataTable no-footer dtr-column"
                              id="DataTables_Table_0"
                              style={{ width: '100%' }}
                           >
                              <thead>
                                 <tr>
                                    <th>Nom</th>
                                    <th>Actions</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {Array.isArray(filteredSubCategories) && getPaginatedData().length > 0 ? (
                                    getPaginatedData().map((categorie) => (
                                       <tr key={categorie.id}>
                                          <td>{categorie.name}</td>
                                          <td className="text-start">
                                             <Link to={`${categorie.id}/attributes`}>Attributes</Link>
                                             <div className="d-inline-block text-nowrap">
                                                <button className="btn btn-sm btn-icon" onClick={() => toggleModal(categorie, 'update')} title="Modifier">
                                                   <i className="bx bx-edit"></i>
                                                </button>
                                                <button className="btn btn-sm btn-icon" onClick={() => toggleModal(categorie, 'delete')} title="Supprimer">
                                                   <i className="bx bx-trash"></i>
                                                </button>
                                             </div>
                                          </td>
                                       </tr>
                                    ))
                                 ) : (
                                    <tr>
                                       <td colSpan="9">Aucune subcategorie trouvée.</td>
                                    </tr>
                                 )}
                              </tbody>
                           </table>
                           <div className="row mx-2 mt-4 justify-content-end">
                              <div className="col-sm-12 col-md-6 text-right">
                                 <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                                    Affichage de {((currentPage - 1) * itemsPerPage) + 1} à {Math.min(currentPage * itemsPerPage, filteredSubCategories.length)} sur {filteredSubCategories.length} entrées
                                 </div>
                              </div>
                              <div className="col-sm-12 col-md-6">
                                 <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                                    {renderPagination()}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {/* Modal */}
         <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
               <Modal.Title>
                  {modalMode === 'create' ? 'Ajouter SubCategorie' : modalMode === 'update' ? 'Modifier SubCategorie' : 'Supprimer SubCategorie'}
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               {modalMode === 'delete' ? (
                  <p>Êtes-vous sûr de vouloir supprimer cette subcategorie?</p>
               ) : (
                  <div>
                     <div className="form-group">
                        <label htmlFor="schoolNameFr">Nom :</label>
                        <input
                           type="text"
                           className="form-control"
                           id="schoolNameFr"
                           placeholder="Nom (FR)"
                           value={currentSubCategory?.name || ''}
                           onChange={(e) => setCurrentSubCategory({ ...currentSubCategory, name: e.target.value })}
                        />
                     </div>
                  </div>
               )}
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={() => setShowModal(false)}>
                  Fermer
               </Button>
               {modalMode === 'delete' ? (
                  <Button variant="danger" onClick={handleDelete}>
                     Supprimer
                  </Button>
               ) : (
                  <Button variant="primary" onClick={handleCreateOrUpdate}>
                     {modalMode === 'create' ? 'Ajouter' : 'Modifier'}
                  </Button>
               )}
            </Modal.Footer>
         </Modal>
      </div>
   );
};

export default SubCategories;

